// src/context/ThemeContext.js
import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { GlobalStyles } from '@mui/system';
import { useDarkMode } from 'storybook-dark-mode';

const ThemeContext = createContext({
  theme: createTheme({ palette: { mode: 'light' } }),
  toggleTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const isDarkModeFromStorybook = useDarkMode?.() ?? false; // Always call the hook unconditionally

  const [themeMode, setThemeMode] = useState(() => {
    const savedThemeMode = localStorage.getItem('themeMode');
    return savedThemeMode || 'light';
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && window.STORYBOOK_ENV) {
      setThemeMode(isDarkModeFromStorybook ? 'dark' : 'light');
    } else {
      localStorage.setItem('themeMode', themeMode);
    }
  }, [themeMode, isDarkModeFromStorybook]);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkModeFromStorybook ? 'dark' : themeMode,
        },
        components: {
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                transition: 'fill 300ms',
              },
            },
          },
        },
      }),
    [themeMode, isDarkModeFromStorybook]
  );

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={{ body: { transition: 'background-color 300ms' } }} />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
