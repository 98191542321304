import React, { useState, useEffect, useMemo } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Modal, Box, TextField, Button, Typography, Autocomplete,
  Snackbar, useTheme
} from '@mui/material';
import Alert from '@mui/material/Alert'; // Import Alert component for Snackbar
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../../../context/AuthContext';
import LocalityAutocomplete from './../../molecules/LocalityAutocomplete/LocalityAutocomplete';
import UserAutocomplete from '../../molecules/UserAutocomplete/UserAutocomplete';
import getConfig from '../../../config'; // Import getConfig

function VacationsTable({ data, onRowSelect, onDelete, onInvite }) {
  const theme = useTheme(); // Hook to access the theme
  const { getToken } = useAuth();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    id: '',
    title: '',
    description: '',
    start_date: null,
    end_date: null,
    localities: [],
    users: []
  });
  const [selectedUsers, setSelectedUsers] = useState([]); // State to manage multiple users
  const [selectedLocality, setSelectedLocality] = useState(null); // State to manage selected locality
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [config, setConfig] = useState(null); // State to manage config

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  const getSeason = (date) => {
    const month = date.month();
    switch (true) {
      case (month >= 2 && month <= 4):  // March to May
        return 'Spring';
      case (month >= 5 && month <= 7):  // June to August
        return 'Summer';
      case (month >= 8 && month <= 10):  // September to November
        return 'Fall';
      default:
        return 'Winter';  // December to February
    }
  }

  const formatDateRangeWithSeason = (startDate, endDate) => {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);
    const duration = moment.duration(end.diff(start));

    // Calculate full months, weeks, and days
    const months = duration.months();
    const weeks = duration.weeks() % 4;  // Weeks remainder after full months
    const days = duration.days() % 7;    // Days remainder after full weeks

    // Formatting duration string based on the length of the interval
    let durationString = '';
    if (months > 0) {
      durationString += `${months} month${months > 1 ? 's' : ''} `;
    }
    if (weeks > 0) {
      durationString += `${weeks} week${weeks > 1 ? 's' : ''} `;
    }
    if (days > 0) {
      durationString += `${days} day${days > 1 ? 's' : ''}`;
    }

    const startYear = start.year();
    const endYear = end.year();
    const season = getSeason(start);
    const yearAbbreviation = start.format('YY');  // Two-digit year

    // Determine the prefix for the season based on whether the start and end dates are in the same year
    const seasonPrefix = startYear === endYear ? "this" : "in";

    // Adjust the return string based on the seasonPrefix
    if (seasonPrefix === "this") {
      return `${durationString.trim()} ${seasonPrefix} ${season}`;  // Omit year if 'this' season
    } else {
      return `${durationString.trim()} ${seasonPrefix} ${season} '${yearAbbreviation}`;
    }
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const handleEditVacation = (vacation) => {
    setEditFormData({
      ...vacation,
      start_date: new Date(vacation.start_date),
      end_date: new Date(vacation.end_date)
    });
    setEditModalOpen(true);
  };

  const handleEditSubmit = async () => {
    const token = await getToken();
    const url = `${config.apiBaseUrl}/vacations/${editFormData.id}/`;

    // Check if locality has been modified and selectedLocality is defined
    const localityModified = selectedLocality && !editFormData.localities.includes(selectedLocality.id);

    // If the locality is modified and valid, update the localities array; otherwise, keep the original
    let updatedLocalities = [editFormData.localities];
    if (localityModified) {
      updatedLocalities = [...editFormData.localities, selectedLocality.id]; // Assuming selectedLocality.id is the correct identifier
    } else {
      updatedLocalities = [editFormData.localities[0].id];
    }

    // Prepare the updated form data with potentially updated localities
    const updatedFormData = {
      ...editFormData,
      localities: updatedLocalities
    };

    // Make the API call to update the vacation
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedFormData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to update vacation:', errorText);
      throw new Error('Failed to update vacation');
    }

    const updatedVacation = await response.json();
    setEditModalOpen(false);
  };

  const addUserToVacation = async () => {
    if (selectedUsers.length === 0) {
      setSnackbar({ open: true, message: 'Please select at least one user to add.', severity: 'warning' });
      return;
    }

    const token = getToken();
    const url = `${config.apiBaseUrl}/vacations/${editFormData.id}/users/`;

    try {
      for (const user of selectedUsers) {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            username: user.username,
            access_level: 'contributor'
          })
        });

        if (!response.ok) {
          if (response.status === 409) {
            const errorData = await response.json();
            setSnackbar({ open: true, message: errorData.message || `${user.username} is already part of this vacation.`, severity: 'info' });
          } else {
            const errorData = await response.json();
            throw new Error(`Failed to add user to vacation: ${errorData.message}`);
          }
        } else {
          setSnackbar({ open: true, message: `${user.username} added to the vacation successfully.`, severity: 'success' });
        }
      }
      setSelectedUsers([]); // Reset user selection after successful addition
    } catch (error) {
      console.error('Error adding user to vacation:', error.message);
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const editModalBody = (
    <Modal
      open={editModalOpen}
      onClose={() => setEditModalOpen(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4 }}>
        <Typography id="modal-title" variant="h6">Edit Vacation</Typography>
        <TextField
          label="Title"
          fullWidth
          margin="normal"
          value={editFormData.title}
          onChange={(e) => setEditFormData({ ...editFormData, title: e.target.value })}
        />
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={editFormData.description}
          onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
        />

        <LocalityAutocomplete
          onSelect={(newValue) => setSelectedLocality(newValue)}
          defaultValue={editFormData.localities[0]} // Prepopulate with current locality data
        />
        <Button onClick={handleEditSubmit} variant="contained" sx={{ mt: 2 }}>Save Changes</Button>
        <UserAutocomplete
          onSelect={(newUsers) => setSelectedUsers(newUsers)} // Update to handle multiple users
        />
        <Button onClick={addUserToVacation} variant="contained" sx={{ mt: 2 }}>Add User</Button>
      </Box>
    </Modal>
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="vacations table">
          <TableHead style={{ backgroundColor: '#1876D2' }}>
            <TableRow>
              {["Title", "Length", "Destinations", "Last Edited", "Actions"].map((head, index) => (
                <TableCell 
                  key={index} 
                  style={{ 
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    cursor: 'pointer' 
                  }} 
                  onClick={() => handleSort(head.toLowerCase())}
                >
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <TableRow
                key={row.id}
                hover
                onClick={() => {
                  onRowSelect(row)
                }}
                style={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">{row.title}</TableCell>
                <TableCell align="right">
                  {formatDateRangeWithSeason(row.start_date, row.end_date)}
                </TableCell>
                <TableCell align="right">{row.localities.map(loc => `${loc.city}, ${loc.country.iso2}`).join(', ')}</TableCell>
                <TableCell align="right">{moment.utc(row.updated_at).fromNow()}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(event) => { event.stopPropagation(); onDelete(row.id); }} color="error">
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={(event) => { event.stopPropagation(); handleEditVacation(row); }} color="primary">
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {editModalBody}
      </TableContainer>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default VacationsTable;
