import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import getConfig from '../config'; // Import getConfig to load configuration

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => JSON.parse(localStorage.getItem('tokens')));
    const [loading, setLoading] = useState(true); // Start with loading true
    const [config, setConfig] = useState(null);

    useEffect(() => {
        const loadConfig = async () => {
            const configData = await getConfig();
            setConfig(configData);
        };

        loadConfig();
    }, []);

    useEffect(() => {
        const checkAuthTokens = () => {
            if (authTokens) {
                setLoading(false); // Set loading to false if tokens are already set
            } else {
                setLoading(false); // Optionally, handle unauthenticated state
            }
        };

        checkAuthTokens();
    }, [authTokens]);

    useEffect(() => {
        if (!authTokens || !config) {
            return; // If no authTokens or config, skip setting up axios
        }

        const axiosInstance = axios.create({
            baseURL: config.apiBaseUrl, // Use the base URL from config
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens?.access_token}`
            }
        });

        axiosInstance.interceptors.response.use(response => response, async error => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const rs = await refreshAccessToken();
                    if (rs.access_token) {
                        axios.defaults.headers.common['Authorization'] = `Bearer ${rs.access_token}`;
                        originalRequest.headers['Authorization'] = `Bearer ${rs.access_token}`;
                        return axios(originalRequest);
                    }
                } catch (refreshError) {
                    console.log('Unable to refresh token:', refreshError);
                    return Promise.reject(refreshError);
                }
            }
            return Promise.reject(error);
        });

        const refreshAccessToken = async () => {
            try {
                const response = await axios.post(`${config.apiBaseUrl}/auth/refresh`, {}, {
                    headers: { 'Authorization': `Bearer ${authTokens.refresh_token}` }
                });
                const { access_token } = response.data;
                setAuthTokens(prev => ({ ...prev, access_token }));
                localStorage.setItem('tokens', JSON.stringify({ ...authTokens, access_token }));
                return { access_token };
            } catch (error) {
                console.error("Refresh token API error: ", error);
                logout(); // Clear tokens on failure
            }
        };

        return () => {
            axios.defaults.headers.common['Authorization'] = null;
        };
    }, [authTokens, config]);

    const login = (tokens) => {
        localStorage.setItem('tokens', JSON.stringify(tokens));
        setAuthTokens(tokens);
    };

    const logout = () => {
        localStorage.removeItem('tokens');
        setAuthTokens(null);
    };

    const getToken = () => {
        return authTokens ? authTokens.access_token : null;
    };

    if (loading) {
        return <React.Fragment>Loading...</React.Fragment>;
    }

    return (
        <AuthContext.Provider value={{ authTokens, login, logout, getToken, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
