import React, { useState, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import { Box, Typography, Snackbar, Alert, Autocomplete, TextField, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import debounce from 'lodash.debounce';
import AirportPicker from '../AirportPicker/AirportPicker';
import getConfig from '../../../config';

const useStyles = styled((theme) => ({
  formContainer: {
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    maxWidth: 800,
    margin: 'auto',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const planeCabins = ['Economy', 'Business Class', 'First Class'];

const TravelFormAirTravel = forwardRef(({ initialData, onSave }, ref) => {
  const classes = useStyles();
  const { getToken } = useAuth();
  const { theme } = useTheme();

  const [location, setLocation] = useState(initialData.personalTravel?.hometown?.location || { lat: 0, lon: 0 });
  const [radius, setRadius] = useState(initialData.airTravel.radius || 100);
  const [selectedAirports, setSelectedAirports] = useState(initialData.airTravel.selectedAirports || []);
  const [formData, setFormData] = useState({ ...initialData });
  const [airlines, setAirlines] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm() {
      // Update the formData with the selected airports before saving
      onSave({ ...formData, airTravel: { ...formData.airTravel, selectedAirports } });
    },
  }));

  useEffect(() => {
    // Update formData.airTravel.selectedAirports whenever selectedAirports changes
    setFormData((prevData) => ({
      ...prevData,
      airTravel: {
        ...prevData.airTravel,
        selectedAirports,
      },
    }));
  }, [selectedAirports]);

  const handleAutocompleteChange = (field) => (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      airTravel: { ...prevData.airTravel, [field]: newValue || [] },
    }));
  };

  const fetchAirlines = async (name) => {
    const token = await getToken();
    if (!config) {
      console.error('Config not loaded');
      return;
    }

    try {
      const url = new URL(`${config.apiBaseUrl}/airlines/`);
      url.searchParams.append('name', name);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setAirlines(data.data || []);
      } else {
        throw new Error(data.message || 'Failed to fetch airlines');
      }
    } catch (error) {
      console.error('Error fetching airlines:', error);
      setSnackbar({ open: true, message: 'Failed to load airlines', severity: 'error' });
    }
  };

  const debounceFetchAirlines = useCallback(debounce((query) => {
    fetchAirlines(query);
  }, 300), [config]);

  const handleAirlineInputChange = (event, value) => {
    if (value) {
      debounceFetchAirlines(value);
    }
  };

  const handleRadiusChange = (newRadius) => {
    setRadius(newRadius);
  };

  const handleAirportSelect = (id) => {
    setSelectedAirports((prev) => {
      const isSelected = prev.includes(id);
      return isSelected ? prev.filter((a) => a !== id) : [...prev, id];
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ open: false });
  };

  return (
    <Paper className={classes.formContainer} component="form" sx={{ marginTop: 5, marginBottom: 5 }}>
      <Typography variant="h5" gutterBottom>There is More Than One Way to Fly</Typography>
      <Typography variant="body1" gutterBottom>Find airports near you</Typography>

      <AirportPicker
        location={location}
        radius={radius}
        selectedAirports={selectedAirports}
        onRadiusChange={handleRadiusChange}
        onAirportSelect={handleAirportSelect}
      />

      <Box className={classes.formControl}>
        <Autocomplete
          multiple
          options={planeCabins}
          value={formData.airTravel.preferredCabins}
          onChange={handleAutocompleteChange('preferredCabins')}
          isOptionEqualToValue={(option, value) => option === value}
          renderInput={(params) => <TextField {...params} label="Preferred Cabins" variant="outlined" />}
        />
      </Box>

      <Box className={classes.formControl}>
        <Autocomplete
          multiple
          options={airlines}
          getOptionLabel={(option) => option.name}
          value={formData.airTravel.preferredAirlines}
          onInputChange={handleAirlineInputChange}
          renderOption={(props, option) => <Typography {...props}>{option.name} ({option.country.iso2})</Typography>}
          onChange={handleAutocompleteChange('preferredAirlines')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="Preferred Airlines" variant="outlined" />}
        />
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default TravelFormAirTravel;
