import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import gravatar from 'gravatar';

const TravelerProfiles = ({ travelProfiles }) => (
  <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">Traveler Profiles</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Home Airports</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Preferred Cabin Class</TableCell>
              <TableCell>Bed Size Preference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {travelProfiles.map((profile) => (
              <TableRow key={profile.user.id}>
                <TableCell>
                  <ul>
                    {profile.airports.map((airport) => (
                      <li key={airport.id}>{airport.name}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell>
                    <Avatar src={gravatar.url(profile.user.email, { s: '100', d: 'identicon' })} />
                    {profile.user.username}
                </TableCell>
                <TableCell>{profile.user.email}</TableCell>
                <TableCell>
                  {profile.cabin_class.map((cabin, index) => (
                    <span key={cabin}>
                      {_.startCase(_.toLower(cabin))}
                      {index !== profile.cabin_class.length - 1 && ', '}
                    </span>
                  ))}
                </TableCell>
                <TableCell>{profile.hotel_profile?.bed_size || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDetails>
  </Accordion>
);

export default TravelerProfiles;
