import React, { useState, useRef, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import Main from '../../templates/Main/Main';
import TravelFormPersonal from '../../organisms/TravelFormPersonal/TravelFormPersonal';
import TravelFormAirTravel from '../../organisms/TravelFormAirTravel/TravelFormAirTravel';
import TravelFormLodging from '../../organisms/TravelFormLodging/TravelFormLodging';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import getConfig from '../../../config';

const useStyles = styled((theme) => ({
  main: {
    margin: 'auto',
    maxWidth: 1200,
    padding: theme.spacing(4),
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f4f4f4', // Adjust the background color as needed
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
}));

const Onboarding = () => {
  const { getToken } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();

  const [config, setConfig] = useState(null);
  const [step, setStep] = useState(1);
  const formRef = useRef(null); // Reference to the current form
  const [onboardingData, setOnboardingData] = useState({
    personalTravel: {
      hometown: {
        location: {
          lat: 0,
          lon: 0,
        }
      }
    },
    airTravel: {
      radius: 100,
      selectedAirports: [],
    },    
    lodgingTravel: {
      starRating: 3,
      roomSharing: false,
      bedSharing: false,
      handicapAccessibility: false,
      bedSizes: [],
      walkScore: 50,
    }
  });

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  const handleNextStep = () => {
    if (formRef.current && formRef.current.submitForm) {
      formRef.current.submitForm(); // Call submitForm of the current step
    } else {
      // If there's no form to submit (e.g., last step), move to the next step directly
      setStep((prevStep) => prevStep + 1);
      console.log('Current Onboarding Data:', onboardingData); // Log current data for each step
    }

    // When finishing, log that the final data has been submitted
    if (step === 3) {
      submitTravelProfile();
      submitUserProfile();
      console.log('Final Onboarding Data:', onboardingData);
      navigate('/');
    }
  };

  const submitTravelProfile = async () => {
    const travelProfile = {
      cabin_class: onboardingData.airTravel.preferredCabins,
      handicap_accessibility: onboardingData.lodgingTravel.handicapAccessibility,
      airports: onboardingData.airTravel.selectedAirports,
      hometown: onboardingData.personalTravel.hometown,
      travel_partner: onboardingData.personalTravel.travelPartner,
      hotel_profile: {
        bed_sharing: onboardingData.lodgingTravel.bedSharing,
        hotel_rating: onboardingData.lodgingTravel.starRating,
        room_sharing: onboardingData.lodgingTravel.roomSharing,
        bed_size: onboardingData.lodgingTravel.bedSizes.length > 0 ? onboardingData.lodgingTravel.bedSizes[0] : 'queen'
      }
    };
    const token = await getToken();
    if (!config) {
      console.error('Configuration not loaded');
      return;
    }

    const url = `${config.apiBaseUrl}/travel_profiles/`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(travelProfile)
      });

      const data = await response.json();
      console.log('Travel profile submitted successfully:', data);
    } catch (error) {
      console.error('Error submitting travel profile:', error);
    }
  };

  const submitUserProfile = async () => {
    const userProfile = {
      first_name: onboardingData.personalTravel.firstName,
      last_name: onboardingData.personalTravel.lastName
    };

    const token = await getToken();
    if (!config) {
      console.error('Configuration not loaded');
      return;
    }

    const url = `${config.apiBaseUrl}/user/`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userProfile)
      });

      const data = await response.json();
      console.log('User profile submitted successfully:', data);
    } catch (error) {
      console.error('Error submitting user profile:', error);
    }
  };

  const handlePreviousStep = () => {
    // Move to the previous step, ensuring it doesn't go below step 1
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const saveFormData = (data) => {
    // Update the relevant section of the onboarding data
    setOnboardingData(data);

    // Move to the next step after saving data
    setStep((prevStep) => prevStep + 1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <TravelFormPersonal
            ref={formRef}
            initialData={onboardingData}
            onSave={saveFormData}
          />
        );
      case 2:
        return (
          <TravelFormAirTravel
            ref={formRef}
            initialData={onboardingData}
            onSave={saveFormData}
          />
        );
      case 3:
        return (
          <TravelFormLodging
            ref={formRef}
            initialData={onboardingData}
            onSave={saveFormData}
          />
        );
      default:
        return <div>Step {step} content here</div>;
    }
  };

  return (
    <Main className={classes.main}>
      {renderStepContent()}
      <Box className={classes.buttonContainer}>
        <Button onClick={handlePreviousStep} disabled={step === 1}>
          Previous
        </Button>
        <Button onClick={handleNextStep}>
          {step === 3 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Main>
  );
};

export default Onboarding;
