import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import AirportPicker from './AirportPicker';

const AirportPickerModal = ({
  open,
  onClose,
  onSave,
  location,
  radius,
  selectedAirports,
  onRadiusChange,
  onAirportSelect,
  disableExit = false,
}) => {
  const handleSave = () => {
    onSave(selectedAirports);
  };

  return (
    <Dialog
      open={open}
      onClose={!disableExit ? onClose : null}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown={disableExit}
    >
      <DialogTitle>Select Airports</DialogTitle>
      <DialogContent>
        <AirportPicker
          location={location}
          radius={radius}
          selectedAirports={selectedAirports}
          onRadiusChange={onRadiusChange}
          onAirportSelect={onAirportSelect}
        />
      </DialogContent>
      <DialogActions>
        {!disableExit && (
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        )}
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AirportPickerModal;
