import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import debounce from 'lodash/debounce';
import { useAuth } from '../../../context/AuthContext';
import getConfig from '../../../config';

// Enhanced chip label function with better null-check handling
const defaultChipLabel = (option) => {
  if (!option || !option.city || !option.state) {
    return ''; // Return empty string if data is incomplete
  }

  const countryCode = option.country ? option.country.iso2 : '';
  return `${option.city}, ${option.state}${countryCode ? `, ${countryCode}` : ''}`;
};

function LocalityAutocomplete({ onSelect, value = null, inputLabel = "Search Locations", chipLabel = defaultChipLabel, multiple = true }) {
  const { getToken } = useAuth();
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(value || (multiple ? [] : null));
  const [config, setConfig] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
      setConfigLoaded(true);
    };

    loadConfig();
  }, []);

  // Sync the external `value` prop with internal state
  useEffect(() => {
    setSelectedLocations(value || (multiple ? [] : null));
  }, [value, multiple]);

  // Fetch locations based on the input query
  const fetchLocations = async (searchQuery) => {
    if (!configLoaded) {
      return;
    }

    const token = await getToken();
    const url = `${config.apiBaseUrl}/localities/search?query=${encodeURIComponent(searchQuery)}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLocationOptions(data.data || []);
      } else {
        console.error('Failed to fetch locations');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  // Debounced input change handler
  const handleInputChange = debounce((event, newInputValue) => {
    if (newInputValue) {
      fetchLocations(newInputValue);
    }
  }, 300);

  // Handle change and update parent via `onSelect`
  const handleChange = (event, newValue) => {
    setSelectedLocations(newValue);
    onSelect(newValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      id="location-search"
      options={locationOptions}
      value={selectedLocations}
      getOptionLabel={(option) => chipLabel(option)}
      style={{ marginTop: 16 }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} variant="outlined" />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...restProps } = getTagProps({ index });
          return (
            <Chip
              key={option.id}  // Explicitly set the key prop here
              label={chipLabel(option)}
              {...restProps}
            />
          );
        })
      }
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export default LocalityAutocomplete;
