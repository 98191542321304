import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/system';
import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Root = styled(Paper)(({ theme, score }) => ({
  padding: theme.spacing(1),
  backgroundColor: score >= 4 ? theme.palette.error.main : score >= 2.5 ? theme.palette.warning.main : theme.palette.success.main,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  width: '10em',
  height: '5.5em',
  textAlign: 'center',
  margin: 10,
}));

const Score = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.h3.fontWeight,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.h3.fontWeight,
  marginBottom: '-1em',
}));

const TravelAdvisory = ({ countryCode }) => {
  const [advisory, setAdvisory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdvisory = async () => {
      try {
        const response = await axios.get(`https://www.travel-advisory.info/api?countrycode=${countryCode}`);
        const advisoryData = response.data.data[countryCode];
        setAdvisory(advisoryData.advisory);
      } catch (err) {
        setError('Unable to fetch travel advisory');
      } finally {
        setLoading(false);
      }
    };

    fetchAdvisory();
  }, [countryCode]);

  const score = advisory ? advisory.score : 0;

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error}</Typography>;

  return (
    <Root score={score}>
      <Title variant="h6">Travel Advisory</Title>
      <Score>{score}/5</Score>
    </Root>
  );
};

TravelAdvisory.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

export default TravelAdvisory;
