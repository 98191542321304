import React from 'react';
import { IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled } from '@mui/system';

const FavoriteIconBox = styled('div')({
  position: 'absolute',
  right: 8,
  top: 8,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledFavoriteBorderIcon = styled(FavoriteBorderIcon)({
  fontSize: '32px',
  color: 'white',
  position: 'absolute',
  zIndex: 1,
});

const StyledFavoriteIconButton = styled(IconButton)(({ isFavorite }) => ({
  zIndex: 2,
  color: isFavorite ? 'red' : 'white',
}));

const FavoriteButton = ({ isFavorite, onClick }) => (
  <FavoriteIconBox>
    <StyledFavoriteIconButton onClick={onClick} isFavorite={isFavorite}>
      {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </StyledFavoriteIconButton>
  </FavoriteIconBox>
);

export default FavoriteButton;
