import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Rating, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled } from '@mui/system';
import LazyImage from '../../atoms/LazyImage/LazyImage';

const PlaceCardContainer = styled(Card)({
  flex: '0 0 auto',
  width: '300px',
  margin: '4px',
});

const ImageBox = styled(Box)({
  position: 'relative',
  height: '140px',
  overflow: 'hidden',
});

const PrevIconButton = styled(IconButton)({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

const NextIconButton = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

const FavoriteIconBox = styled(Box)({
  position: 'absolute',
  right: 8,
  top: 8,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledFavoriteBorderIcon = styled(FavoriteBorderIcon)({
  fontSize: '32px',
  color: 'white',
  position: 'absolute',
  zIndex: 1,
});

const StyledFavoriteIconButton = styled(IconButton)(({ isFavorite }) => ({
  zIndex: 2,
  color: isFavorite ? 'red' : 'black',
}));

const RatingBox = styled(Box)({
  position: 'absolute',
  left: 8,
  bottom: 8,
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
});

const CustomCardContent = styled(CardContent)({
  paddingBottom: '8px',
  padding: '8px',
});

const PlaceCard = ({ name, address, rating, imageUrls }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handleToggleFavorite = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  };

  return (
    <PlaceCardContainer>
      <ImageBox>
        {imageUrls.length > 1 && (
          <>
            <PrevIconButton onClick={handlePrevImage}>
              <ChevronLeftIcon />
            </PrevIconButton>
            <NextIconButton onClick={handleNextImage}>
              <ChevronRightIcon />
            </NextIconButton>
          </>
        )}
        <CardMedia component="div" height="140">
          <LazyImage src={imageUrls[currentImageIndex]} alt={name} style={{ width: '100%', height: '140px', objectFit: 'cover' }} />
        </CardMedia>
        <FavoriteIconBox>
          <StyledFavoriteBorderIcon />
          <StyledFavoriteIconButton onClick={handleToggleFavorite} isFavorite={isFavorite}>
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </StyledFavoriteIconButton>
        </FavoriteIconBox>
      </ImageBox>
      <CustomCardContent>
        <Typography variant="h6">{name}</Typography>
        {address && <Typography variant="body2">{address}</Typography>}
        <Box display="flex" alignItems="center" mt={1}>
          <Rating value={rating} readOnly />
          <Typography variant="body2" ml={1}>{rating}</Typography>
        </Box>
      </CustomCardContent>
    </PlaceCardContainer>
  );
};

export default PlaceCard;
