import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import TravelProfileForm from './TravelProfileForm';

function TravelProfileModal({ open, onClose, travelProfile = {}, onSave }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" align='center' gutterBottom>Vacation Profile</Typography>
        <TravelProfileForm travelProfile={travelProfile} onSave={onSave} />
      </Box>
    </Modal>
  );
}

export default TravelProfileModal;
