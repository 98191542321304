import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Rating,
  Autocomplete,
  FormControl,
  Chip,
} from '@mui/material';
import LocalityAutocomplete from '../../molecules/LocalityAutocomplete/LocalityAutocomplete';
import AirportPickerModal from '../../organisms/AirportPicker/AirportPickerModal';
import { useAuth } from '../../../context/AuthContext';
import AirlineAutocomplete from '../../molecules/AirlineAutocomplete/AirlineAutocomplete';
import { styled } from '@mui/system';
import getConfig from '../../../config';

const cabinOptions = [
  { title: 'Economy' },
  { title: 'Premium Economy' },
  { title: 'Business Class' },
  { title: 'First Class' },
];

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const TravelProfileForm = ({ travelProfile = {}, onSave }) => {
  const { getToken } = useAuth();
  const [profileData, setProfileData] = useState({
    handicap_accessibility: false,
    hotel_profile: {
      hotel_rating: 0,
      bed_sharing: false,
      room_sharing: false,
    },
    airports: [],
    airlines: [],
    cabin_class: [],
    hometown: null,
    ...travelProfile,
  });
  const [openAirportPicker, setOpenAirportPicker] = useState(false);
  const [searchRadius, setSearchRadius] = useState(100);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  useEffect(() => {
    if (travelProfile) {
      const newTravelProfile = {
        handicap_accessibility: travelProfile?.handicap_accessibility || false,
        hotel_profile: {
          hotel_rating: travelProfile.hotel_profile?.hotel_rating || 0,
          bed_sharing: travelProfile.hotel_profile?.bed_sharing || false,
          room_sharing: travelProfile.hotel_profile?.room_sharing || false,
        },
        airports: travelProfile.airports || [],
        airlines: travelProfile.airlines || [],
        cabin_class: travelProfile.cabin_class || [],
        hometown: travelProfile.hometown || null,
      };
      setProfileData(newTravelProfile);
    }
  }, [travelProfile]);

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'handicap_accessibility') {
      setProfileData({ ...profileData, [name]: checked });
    } else {
      setProfileData({ ...profileData, hotel_profile: { ...profileData.hotel_profile, [name]: checked } });
    }
  };

  const handleHometownSelect = (selectedHometown) => {
    setProfileData({ ...profileData, hometown: selectedHometown });
  };

  const handleSave = async () => {
    console.log('Saving profile:', profileData);
    await onSave(profileData);
    await fetchUpdatedProfile(); // Fetch the updated profile after saving
  };

  const handleAirportPickerSave = (selectedAirports) => {
    setProfileData((prevState) => ({
      ...prevState,
      airports: selectedAirports,
    }));
    setOpenAirportPicker(false);
  };

  const handleAirportSelect = (selectedAirport) => {
    setProfileData((prevState) => {
      const isSelected = prevState.airports.some((airport) => airport.id === selectedAirport.id);
      const updatedAirports = isSelected
        ? prevState.airports.filter((airport) => airport.id !== selectedAirport.id)
        : [...prevState.airports, selectedAirport];
      return { ...prevState, airports: updatedAirports };
    });
  };

  const fetchUpdatedProfile = async () => {
    const token = await getToken();
    if (!token || !config) return;

    try {
      const response = await fetch(`${config.apiBaseUrl}/user/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch profile');
      }

      const updatedProfile = await response.json();
      setProfileData(updatedProfile);
    } catch (error) {
      console.error('Error fetching updated profile:', error.message);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Personal Details Section */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Personal Details
            </Typography>
            <LocalityAutocomplete
              onSelect={handleHometownSelect}
              value={profileData.hometown}
              inputLabel="Hometown"
              multiple={false}
            />
            <StyledButton variant="contained" onClick={() => setOpenAirportPicker(true)}>
              Select Hometown Airport
            </StyledButton>
          </StyledPaper>
        </Grid>

        {/* Air Travel Preferences Section */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Air Travel Preferences
            </Typography>
            <AirlineAutocomplete
              selectedAirlines={profileData.airlines}
              setSelectedAirlines={(newValue) => setProfileData({ ...profileData, airlines: newValue })}
            />
            <Autocomplete
              multiple
              options={cabinOptions}
              getOptionLabel={(option) => option.title}
              value={(profileData.cabin_class || []).map((cabin) =>
                cabinOptions.find((option) => option.title.toLowerCase() === cabin.toLowerCase())
              ).filter(Boolean)}
              onChange={(event, newValue) => {
                setProfileData({
                  ...profileData,
                  cabin_class: newValue.map((option) => toTitleCase(option.title)),
                });
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...restProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={option.title}
                      label={option.title}
                      {...restProps}
                    />
                  );
                })
              }
              renderInput={(params) => <TextField {...params} label="Cabin Class" margin="normal" />}
            />
          </StyledPaper>
        </Grid>

        {/* Hotel Preferences Section */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Hotel Preferences
            </Typography>
            <FormControl fullWidth margin="normal">
              <Typography component="legend">Star Rating</Typography>
              <Rating
                value={profileData.hotel_profile?.hotel_rating}
                onChange={(event, newValue) =>
                  setProfileData({
                    ...profileData,
                    hotel_profile: {
                      ...profileData.hotel_profile,
                      hotel_rating: newValue,
                    },
                  })
                }
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.hotel_profile?.bed_sharing}
                  onChange={handleCheckboxChange}
                  name="bed_sharing"
                />
              }
              label="Bed Sharing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.hotel_profile?.room_sharing}
                  onChange={handleCheckboxChange}
                  name="room_sharing"
                />
              }
              label="Room Sharing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.handicap_accessibility}
                  onChange={handleCheckboxChange}
                  name="handicap_accessibility"
                />
              }
              label="Handicap Accessibility"
            />
          </StyledPaper>
        </Grid>
      </Grid>
      <StyledButton variant="contained" color="primary" onClick={handleSave}>
        Save
      </StyledButton>
      <AirportPickerModal
        open={openAirportPicker}
        onClose={() => setOpenAirportPicker(false)}
        onSave={handleAirportPickerSave}
        location={profileData.hometown?.location}
        radius={searchRadius}
        selectedAirports={profileData.airports}
        onRadiusChange={(newRadius) => {
          setSearchRadius(newRadius);
        }}
        onAirportSelect={handleAirportSelect}
        disableExit={false}
      />
    </Box>
  );
};

TravelProfileForm.propTypes = {
  travelProfile: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default TravelProfileForm;
