import React, { useEffect, useState, useCallback, useRef } from 'react';
import { APIProvider, Map, InfoWindow, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext'; // Adjust the import path as needed
import { useTheme, styled } from '@mui/material/styles';
import getConfig from '../../../config';
import log from 'loglevel';

const MapContainer = styled('div')({
  width: '100%',
  height: '400px',
  zIndex: 1,
});

const markerColors = {
  green: { background: '#0f9d58', borderColor: '#006425', glyphColor: '#60d98f' },
  red: { background: '#db4437', borderColor: '#a82e24', glyphColor: '#f1a8a5' },
  yellow: { background: '#f4b400', borderColor: '#c49700', glyphColor: '#ffe380' },
  blue: { background: '#4285f4', borderColor: '#2b6bbf', glyphColor: '#a6d0ff' },
  coral: { background: '#ff6f61', borderColor: '#e94b3c', glyphColor: '#ff9c9b' },
  purple: { background: '#ab47bc', borderColor: '#7b1fa2', glyphColor: '#d6a8d6' },
  cyan: { background: '#00acc1', borderColor: '#007c91', glyphColor: '#80deea' },
  lightGreen: { background: '#8bc34a', borderColor: '#618833', glyphColor: '#c5e1a5' },
  orange: { background: '#ff7043', borderColor: '#c63f17', glyphColor: '#ffab91' },
  indigo: { background: '#5c6bc0', borderColor: '#3f51b5', glyphColor: '#9fa8da' },
};

const getMarkerColors = (color) => {
  return markerColors[color] || markerColors.green; // Default to green if color is not found
};

const DynamicMap = ({
  latitude,
  longitude,
  markers = [], // Default to an empty array
  hideControls = false,
  maxZoom = 20,
  zoomControlPosition = 'TOP_RIGHT',
  onBoundsChanged
}) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState('');
  const { getToken } = useAuth();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [config, setConfig] = useState(null);
  const mapRef = useRef(null); // Add a ref to the map
  const [zoom, setZoom] = useState(13); // Default zoom level

  const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
  const [mapBounds, setMapBounds] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
      log.setLevel(configData.logLevel); // Set the logging level to debug
      log.debug(`Using config for environment: ${configData.environment}`);
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const loadConfigAndFetchApiKey = async () => {
      if (!config) return;

      const token = await getToken();
      if (!token) {
        log.error('Token not found');
        return;
      }

      try {
        const response = await axios.get(`${config.apiBaseUrl}/external/google/maps`, {
          params: {
            lat: latitude,
            lon: longitude,
            zoom: 13
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setGoogleMapsApiKey(response.data.api_key);
      } catch (error) {
        log.error('Error fetching the Google Maps API key:', error);
      }
    };

    loadConfigAndFetchApiKey();
  }, [latitude, longitude, getToken, config]);

  const getZoomControlPosition = (position) => {
    if (window.google && window.google.maps && window.google.maps.ControlPosition) {
      const validPositions = Object.keys(window.google.maps.ControlPosition);
      if (validPositions.includes(position)) {
        return window.google.maps.ControlPosition[position];
      } else {
        log.warn(`Invalid zoom control position: ${position}, defaulting to TOP_RIGHT.`);
        return window.google.maps.ControlPosition.TOP_RIGHT;
      }
    } else {
      return null;
    }
  };

  const mapOptions = useCallback(() => {
    const position = getZoomControlPosition(zoomControlPosition);
    return {
      disableDefaultUI: hideControls,
      zoomControlOptions: {
        position: position,
      },
      maxZoom: maxZoom, // Add maxZoom to map options
    };
  }, [hideControls, zoomControlPosition, maxZoom]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCameraChange = useCallback((event) => {
    const { center, bounds, zoom } = event.detail; // Destructure zoom from event.detail

    if (bounds && center && zoom) { // Check if zoom exists
      const boundsObject = {
        north: bounds.north,
        east: bounds.east,
        south: bounds.south,
        west: bounds.west,
      };
      const centerObject = {
        lat: center.lat,
        lng: center.lng,
      };
      setMapBounds(boundsObject);
      setMapCenter(centerObject);
      setZoom(zoom); // Update zoom state
      if (onBoundsChanged) {
        onBoundsChanged(boundsObject, centerObject, zoom); // Pass zoom to onBoundsChanged
      }
    }
  }, [onBoundsChanged]);

  const handleMapLoad = (map) => {
    log.debug('Map loaded:', map);
    mapRef.current = map;
  };

  if (!config) {
    return <div>Loading configuration...</div>;
  }

  if (!googleMapsApiKey) {
    return <div>Loading map...</div>;
  }

  return (
    <APIProvider apiKey={googleMapsApiKey}>
      <MapContainer>
        <Map
          style={{ width: '100%', height: '100%' }}
          center={mapCenter}
          zoom={zoom} // Use zoom state
          options={mapOptions()}
          mapId={config.google.maps.mapId}
          onLoad={handleMapLoad}
          onCameraChanged={handleCameraChange}
        >
          {markers.map((marker, index) => {
            const { background, borderColor, glyphColor } = getMarkerColors(marker.color);
            return (
              <AdvancedMarker
                key={index}
                position={{ lat: marker.lat, lng: marker.lon }}
                clickable={true}
                onClick={() => handleMarkerClick(marker)}
              >
                <Pin
                  background={background}
                  borderColor={borderColor}
                  glyphColor={glyphColor}
                >
                  {marker.element || null}
                </Pin>
              </AdvancedMarker>
            );
          })}

          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lon }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <h3>{selectedMarker.popup}</h3>
              </div>
            </InfoWindow>
          )}
        </Map>
      </MapContainer>
    </APIProvider>
  );
};

export default DynamicMap;
