import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Hotels = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Hotels</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>Hotel details will be listed here.</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Hotels;
