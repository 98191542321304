import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Rating, IconButton, Skeleton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';
import LazyImage from '../../atoms/LazyImage/LazyImage';
import FavoriteButton from '../../atoms/FavoriteButton/FavoriteButton';

const PlaceCardContainer = styled(Card)({
  flex: '0 0 auto',
  width: '250px',
  margin: '8px',
});

const ImageBox = styled(Box)({
  position: 'relative',
  height: '140px',
  overflow: 'hidden',
});

const ImagesContainer = styled(Box)({
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
});

const ImageWrapper = styled(Box)({
  minWidth: '100%',
  height: '140px',
});

const PrevIconButton = styled(IconButton)({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

const NextIconButton = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

const RatingBox = styled(Box)({
  position: 'absolute',
  left: 8,
  bottom: 8,
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
});

const ContentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const SkeletonContent = styled(Skeleton)({});

const CustomCardContent = styled(CardContent)({
  paddingBottom: '8px',
  padding: '8px',
});

const PlaceCard = ({ title, subtitle, thirdLine, rating, imageUrls }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (title && imageUrls) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [title, imageUrls]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handleToggleFavorite = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  };

  return (
    <PlaceCardContainer>
      <ImageBox>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <>
            <ImagesContainer style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}>
              {imageUrls.map((url, index) => (
                <ImageWrapper key={index}>
                  <LazyImage src={url} alt={`${title} image ${index + 1}`} />
                </ImageWrapper>
              ))}
            </ImagesContainer>
            {imageUrls.length > 1 && (
              <>
                <PrevIconButton onClick={handlePrevImage}>
                  <ChevronLeftIcon />
                </PrevIconButton>
                <NextIconButton onClick={handleNextImage}>
                  <ChevronRightIcon />
                </NextIconButton>
              </>
            )}
            <FavoriteButton isFavorite={isFavorite} onClick={handleToggleFavorite} />
            {rating !== null && rating !== undefined && (
              <RatingBox>
                <Rating value={rating} readOnly precision={0.5} size="small" />
                <Typography variant="body2" ml={1} color="white">{rating}</Typography>
              </RatingBox>
            )}
          </>
        )}
      </ImageBox>
      <CustomCardContent>
        {loading ? (
          <>
            <SkeletonContent variant="text" width="80%" height="28px" />
            <SkeletonContent variant="text" width="60%" height="24px" />
          </>
        ) : (
          <>
            <Typography variant="h8" component="div" sx={{ height: '28px' }}>{title}</Typography>
            {subtitle && <Typography variant="body2" component="div" sx={{ height: '24px' }}>{subtitle}</Typography>}
            {thirdLine && <Typography variant="body2" component="div" sx={{ height: '24px' }}>{thirdLine}</Typography>}
          </>
        )}
      </CustomCardContent>
    </PlaceCardContainer>
  );
};

export default PlaceCard;
