import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useAuth } from '../../../context/AuthContext';
import { Typography, Paper, Grid, Box, Drawer, List, ListItem, ListItemText, useMediaQuery, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Main from '../../templates/Main/Main';
import TravelProfileForm from '../../organisms/TravelProfileModal/TravelProfileForm';
import UserProfileForm from '../../organisms/UserProfileForm/UserProfileForm';
import axios from 'axios';
import getConfig from '../../../config';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const DrawerContainer = styled(Drawer)(({ theme }) => ({
  width: '240px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '240px',
    boxSizing: 'border-box',
    position: 'absolute',
    top: 65,
    height: '100%',
  },
}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const Profile = () => {
  const { getToken } = useAuth();
  const theme = useTheme();
  const [config, setConfig] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [selectedForm, setSelectedForm] = useState('userProfile');
  const [userProfile, setUserProfile] = useState(null);
  const [travelProfile, setTravelProfile] = useState(null);
  const [loadingUserProfile, setLoadingUserProfile] = useState(false);
  const [loadingTravelProfile, setLoadingTravelProfile] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
      setConfigLoaded(true);  // Indicate that the config is loaded
    };

    loadConfig();
  }, []);

  const handleListItemClick = (form) => {
    setSelectedForm(form);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!configLoaded) {
        console.error('Configuration not loaded');
        return;
      }

      setLoadingUserProfile(true);
      const token = await getToken();

      const configHeaders = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const response = await axios.get(`${config.apiBaseUrl}/user/`, configHeaders);
        setUserProfile(response.data.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoadingUserProfile(false);
      }
    };

    const fetchTravelProfile = async () => {
      if (!configLoaded) {
        console.error('Configuration not loaded');
        return;
      }

      setLoadingTravelProfile(true);
      const token = await getToken();

      const configHeaders = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const response = await axios.get(`${config.apiBaseUrl}/travel_profiles/`, configHeaders);

        // get travel profile with vacation == null
        const profile = response.data.data.find((profile) => !profile.vacation);

        setTravelProfile(profile);
      } catch (error) {
        console.error('Error fetching travel profile:', error);
      } finally {
        setLoadingTravelProfile(false);
      }
    };

    if (configLoaded) {
      fetchUserProfile();
      fetchTravelProfile();
    }
  }, [getToken, configLoaded]);

  const handleUserProfileSave = async (updatedProfile) => {
    const token = await getToken();
    const configHeaders = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      await axios.put(`${config.apiBaseUrl}/user/`, updatedProfile, configHeaders);
      setUserProfile(updatedProfile);
    } catch (error) {
      console.error('Error saving user profile:', error);
    }
  };

  const handleTravelProfileSave = async (updatedProfile) => {
    const token = await getToken();
    const configHeaders = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.put(`${config.apiBaseUrl}/travel_profiles/${travelProfile.id}`, updatedProfile, configHeaders);
      setTravelProfile(response.data.data);
    } catch (error) {
      console.error('Error saving travel profile:', error);
    }
  };

  return (
    <Main>
      <StyledBox>
        <Grid container spacing={isMobile ? 2 : 0}>
          {!isMobile && (
            <Grid item xs={12} md={3}>
              <DrawerContainer
                variant="permanent"
                anchor="left"
                open
              >
                <List>
                  <ListItem
                    button
                    selected={selectedForm === 'userProfile'}
                    onClick={() => handleListItemClick('userProfile')}
                  >
                    <ListItemText primary="User Profile" />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedForm === 'travelProfile'}
                    onClick={() => handleListItemClick('travelProfile')}
                  >
                    <ListItemText primary="Travel Profile" />
                  </ListItem>
                </List>
              </DrawerContainer>
            </Grid>
          )}
          <Grid item xs={12} md={isMobile ? 12 : 9}>
            <Content>
              <Paper>
                {(selectedForm === 'userProfile' || isMobile) && (
                  <Section>
                    <Typography variant="h6">User Profile</Typography>
                    {loadingUserProfile ? (
                      <CircularProgress />
                    ) : userProfile ? (
                      <UserProfileForm currentUser={userProfile} onSave={handleUserProfileSave} />
                    ) : (
                      <Typography>Error loading user profile</Typography>
                    )}
                  </Section>
                )}
                {(selectedForm === 'travelProfile' || isMobile) && (
                  <Section>
                    <Typography variant="h6">Travel Profile</Typography>
                    {loadingTravelProfile ? (
                      <CircularProgress />
                    ) : travelProfile ? (
                      <TravelProfileForm travelProfile={travelProfile} onSave={handleTravelProfileSave} />
                    ) : (
                      <Typography>Error loading travel profile</Typography>
                    )}
                  </Section>
                )}
              </Paper>
            </Content>
          </Grid>
        </Grid>
      </StyledBox>
    </Main>
  );
};

export default Profile;
