export const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };
  
  export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  };
  
  export const kilometersToMiles = (km) => {
    return km * 0.621371;
  };
  
  export const calculateAverageDistance = (departingAirports, arrivingAirports) => {
    let totalDistance = 0;
    let count = 0;
  
    departingAirports.forEach((dep) => {
      arrivingAirports.forEach((arr) => {
        const distanceKm = calculateDistance(dep.location.lat, dep.location.lon, arr.location.lat, arr.location.lon);
        totalDistance += distanceKm;
        count += 1;
      });
    });
  
    const averageDistanceKm = totalDistance / count;
    return kilometersToMiles(averageDistanceKm);
  };
  