import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import Main from '../../templates/Main/Main';
import LoginBox from '../../organisms/LoginBox/LoginBox';
import './Login.css'; // Assuming you create a CSS file for login-specific styles

const Login = () => {
  const navigate = useNavigate();

  return (
    <Main>
      <Container className="login-container" maxWidth="sm">
        <LoginBox />
      </Container>
    </Main>
  );
}

export default Login;
