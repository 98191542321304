import log from 'loglevel';

const getConfig = () => {
    const env = process.env.REACT_APP_NODE_ENV || 'development';
  
    let defaultConfig = {};
    let envConfig = {};
  
    try {
      defaultConfig = require('./config/config.default.json');
    } catch (error) {
      console.error('Error loading default config:', error);
    }
  
    try {
      envConfig = require(`./config/config.${env}.json`);
    } catch (error) {
      log.warn(`No specific config for environment: ${env}, using default.`);
    }
  
    return { ...defaultConfig, ...envConfig };
  };
  
  export default getConfig;
  