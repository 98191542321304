import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const UserProfileForm = ({ currentUser, onSave }) => {
  const [userData, setUserData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
  });

  useEffect(() => {
    if (currentUser) {
      setUserData({
        username: currentUser.username || '',
        first_name: currentUser.profile?.first_name || '',
        last_name: currentUser.profile?.last_name || '',
        email: currentUser.email || '',
      });
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: name === 'first_name' || name === 'last_name' ? toTitleCase(value) : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(userData);
  };

  return (
    <StyledPaper>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          name="username"
          value={userData.username}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="First Name"
          name="first_name"
          value={userData.first_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="last_name"
          value={userData.last_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={userData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <StyledButton type="submit" variant="contained" color="primary">
          Save
        </StyledButton>
      </form>
    </StyledPaper>
  );
};

UserProfileForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UserProfileForm;
