import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, Box, Snackbar, Alert, Typography, Paper, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import LocalityAutocomplete from '../../molecules/LocalityAutocomplete/LocalityAutocomplete';
import UserAutocomplete from '../../molecules/UserAutocomplete/UserAutocomplete';

const useStyles = styled((theme) => ({
  formContainer: {
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    maxWidth: 600,
    margin: 'auto',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
  },
}));


const TravelFormPersonal = forwardRef(({ initialData, onSave }, ref) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    ...initialData,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useImperativeHandle(ref, () => ({
    submitForm() {
      onSave(formData);
    },
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      personalTravel: {
        ...prevFormData.personalTravel,
        [name]: value,
      },
    }));
  };

  const handleHometownSelect = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      personalTravel : {
        ...prevFormData.personalTravel,
        hometown: newValue,
      },
    }));
  };

  const handleTravelPartnerSelect = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      personalTravel: {
        ...prevFormData.personalTravel,
        travelPartner: newValue,
      },
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ open: false });
  };

  return (
    <Paper elevation={3} className={classes.formContainer}>
      <Typography variant="h5" gutterBottom>Welcome Explorer!</Typography>
      <Typography variant="body1" gutterBottom>Tell us about yourself:</Typography>

      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.formRow}>
            <TextField
              label="First Name"
              variant="outlined"
              name="firstName"
              value={formData.personalTravel.firstName}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>

          <Box className={classes.formRow}>
            <TextField
              label="Last Name"
              variant="outlined"
              name="lastName"
              value={formData.personalTravel.lastName}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>

          <Box className={classes.formRow}>
            <LocalityAutocomplete
              onSelect={handleHometownSelect}
              inputLabel="Hometown"
              value={formData.personalTravel.hometown}
              multiple={false}
              fullWidth
            />
          </Box>

          <Box className={classes.formRow}>
            <UserAutocomplete
              onSelect={handleTravelPartnerSelect}
              inputLabel="Travel Partner"
              value={formData.personalTravel.travelPartner}
              multiple={false}
              fullWidth
            />
          </Box>
        </CardContent>
      </Card>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default TravelFormPersonal;
