import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip, Typography, Avatar } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import debounce from 'lodash/debounce';
import gravatar from 'gravatar';
import getConfig from '../../../config';

// Helper function to create chip label for each user
const chipLabel = (user) => {
  return user && user.username ? user.username : '';
};

function UserAutocomplete({ onSelect, value = null, inputLabel = "Search Users", multiple = true }) {
  const { getToken } = useAuth();
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(value || (multiple ? [] : null));
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const configData = getConfig();
    setConfig(configData);
  }, []);

  // Effect to synchronize prop value with internal state
  useEffect(() => {
    setSelectedUsers(value || (multiple ? [] : null));
  }, [value, multiple]);

  useEffect(() => {
    // Debounce fetchUsers function
    const debouncedFetchUsers = debounce(fetchUsers, 300);
    return () => {
      debouncedFetchUsers.cancel();
    };
  }, []);

  // Function to fetch users from the backend, matching the input query
  const fetchUsers = async (searchQuery) => {
    const token = await getToken();
    const url = `${config.apiBaseUrl}/users/?username=${encodeURIComponent(searchQuery)}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      // Adjust response based on the actual structure of the API response
      setUserOptions(data.data || []);
    } catch (error) {
      console.error('Failed to fetch Users:', error);
      setUserOptions([]); // Ensure it's always an array even in case of an error
    }
  };

  // Debounced handler to fetch new users based on input changes
  const handleInputChange = debounce((event, newInputValue) => {
    if (newInputValue) {
      fetchUsers(newInputValue);
    }
  }, 300);

  // Handle selection change and call the parent's `onSelect`
  const handleChange = (event, newValue) => {
    setSelectedUsers(newValue);
    onSelect(newValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      id="user-search"
      options={userOptions}
      value={selectedUsers}
      getOptionLabel={(option) => chipLabel(option)}
      style={{ marginTop: 16 }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} variant="outlined" />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Avatar src={gravatar.url(option.email)} sx={{ width: 30, height: 30, marginRight: 1 }} />
          <Typography variant="body1">{option.username}</Typography>
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={chipLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.username === value.username}
    />
  );
}

export default UserAutocomplete;
