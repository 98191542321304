import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Container, Button, AppBar, Toolbar
} from '@mui/material';
import { styled } from '@mui/system';
import Main from '../../templates/Main/Main';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import VacationsTable from './VacationsTable';
import NewVacationModal from '../../organisms/VacationsModalNew/VacationsModalNew';
import DynamicMap from '../../molecules/DynamicMap/DynamicMap'; // Updated import
import { useTheme } from '@mui/material/styles';
import getConfig from '../../../config';
import log from 'loglevel';

// JSS styles converted from the original CSS
const useStyles = styled((theme) => ({
  selectedVacation: {
    marginBottom: 15,
    padding: 10
  },
  noPadding: {
    padding: '0 !important'
  },
  vacationPreview: {
    padding: 10,
    height: '420px',
    '&.vacation-preview-hidden': {
      display: 'none'
    }
  },
  noVacation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5em'
  },
  yesVacation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15em'
  },
  mapContainer: {
    margin: '10px',
    height: '420px',
    width: '100%',
    borderRadius: 10,
    padding: '10px',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: 'black'
  },
  toolbar: {
    justifyContent: 'space-between'
  }
}));

function Vacations() {
  const { getToken } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const isDarkMode = theme.palette.mode === 'dark';

  // Create the background color with transparency using theme colors
  const primaryColorRgb = theme.palette.primary.main.replace('#', '').match(/.{1,2}/g).map(x => parseInt(x, 16)).join(',');
  const backgroundColor = `rgba(${primaryColorRgb}, 0.7)`;

  // Pass dynamic color to useStyles hook
  const classes = useStyles({ backgroundColor });

  const [vacationsData, setVacationsData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalNewVacationOpen, setModalNewVacationOpen] = useState(false);
  const [hometown, setHometown] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const fetchVacations = async () => {
      const token = getToken();
      const url = `${config.apiBaseUrl}/vacations/`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch vacations');
        }

        const data = await response.json();
        setVacationsData(data);
        log.info('Vacations fetched successfully');
        log.debug('Vacations:', data.data);
      } catch (error) {
        log.error('Error fetching vacations:', error.message);
      }
    };

    if (config) {
      fetchVacations();
    }
  }, [config, getToken]);

  useEffect(() => {
    const fetchHometown = async () => {
      const token = getToken();
      const url = `${config.apiBaseUrl}/travel_profiles/`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch travel profile');
        }

        const data = await response.json();
        setHometown(data.data[0].hometown);
        log.info('Hometown fetched successfully');
        log.debug('Hometown:', data.data[0].hometown);
      } catch (error) {
        log.error('Error fetching hometown:', error.message);
      }
    };

    if (config) {
      fetchHometown();
    }
  }, [config, getToken]);

  const handleDelete = async (id) => {
    const token = getToken();
    const url = `${config.apiBaseUrl}/vacations/${id}/`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete vacation');
      }

      const updatedVacations = {
        'data': vacationsData.data.filter(vacation => vacation.id !== id)
      }
      setVacationsData(updatedVacations);
      log.info('Vacation deleted successfully');
    } catch (error) {
      log.error('Error deleting vacation:', error);
    }
  };

  const handleRowSelected = (rowData) => {
    setSelectedRow(rowData);
  };

  const handleExplore = () => {
    if (selectedRow) {
      navigate(`/vacations/${selectedRow.id}`);
    }
  };

  const handleNewVacation = () => {
    setModalNewVacationOpen(true);
  };

  const handleCloseModal = () => {
    setModalNewVacationOpen(false);
  };

  const onVacationCreated = (newVacation) => {
    setVacationsData((prevData) => {
      log.info('New vacation created:', newVacation);
      log.info('Previous vacations:', prevData);
      return {
        data: [...prevData.data, newVacation.data]
      };
    });
  }

  const getAllMarkers = () => {
    if (!vacationsData || !vacationsData.data) return [];

    const vacationMarkers = vacationsData.data.flatMap(vacation => 
      vacation.localities.map(loc => ({
        lat: loc.location.lat,
        lon: loc.location.lon,
        iconUrl: 'https://icon-park.com/imagefiles/location_pin_sphere_red.png', // Red marker for vacation locations
        popup: `<b>${loc.city}</b><br />${loc.country.english_formal}`
      }))
    );

    const hometownMarkers = hometown ? [{
      lat: hometown.location.lat,
      lon: hometown.location.lon,
      iconUrl: 'https://icon-park.com/imagefiles/location_pin_sphere_blue.png', // Blue marker for hometown location
      popup: `<b>${hometown.city}</b><br />Hometown`
    }] : [];

    return [...vacationMarkers, ...hometownMarkers];
  };

  const getBoundsForLocalities = (localities) => {
    if (!localities || localities.length === 0) return null;

    const bounds = localities.map(loc => [loc.location.lat, loc.location.lon]);
    return bounds;
  };

  const getRandomLocalityCoordinates = (localities) => {
    if (!localities || localities.length === 0) return { lat: 0, lon: 0 };

    const randomIndex = Math.floor(Math.random() * localities.length);
    const { location: { lat, lon } } = localities[randomIndex];

    return { lat, lon };
  };

  const allBounds = getBoundsForLocalities(vacationsData ? vacationsData.data.flatMap(v => v.localities) : []);

  return (
    <Main>
      <NewVacationModal isOpen={modalNewVacationOpen} onClose={handleCloseModal} onVacationCreated={onVacationCreated} />
      {vacationsData && vacationsData.data ? (
        <Container>
          <Paper elevation={3} className={`${classes.selectedVacation} ${classes.noPadding}`}>
            <AppBar position="static" color="primary">
              <Toolbar className={classes.toolbar}>
                <Button onClick={handleNewVacation} style={{ color: 'white' }}>New Vacation</Button>
                <Button
                  onClick={handleExplore}
                  color="secondary"
                  disabled={!selectedRow}
                  style={{ color: selectedRow ? 'white' : 'gray' }}
                >
                  Explore
                </Button>
              </Toolbar>
            </AppBar>
            <div className={classes.vacationPreview}>
              <DynamicMap
                latitude={selectedRow ? getRandomLocalityCoordinates(selectedRow.localities).lat : 0}
                longitude={selectedRow ? getRandomLocalityCoordinates(selectedRow.localities).lon : 0}
                markers={selectedRow ? selectedRow.localities.map(loc => ({
                  lat: loc.location.lat,
                  lon: loc.location.lon,
                })) : getAllMarkers()}
                bounds={selectedRow ? getBoundsForLocalities(selectedRow.localities) : allBounds}
                hideControls={false}
                maxZoom={13}
                isDarkMode={isDarkMode}
                zoomControlPosition="BOTTOM_RIGHT"
                className={classes.mapContainer}
              />
            </div>
          </Paper>
          <VacationsTable data={vacationsData.data} onRowSelect={handleRowSelected} onDelete={handleDelete} />
        </Container>
      ) : (
        <Typography variant="body1">Loading...</Typography>
      )}
    </Main>
  );
}

export default Vacations;
