import React from 'react';
import "./Main.css";
import Header from '../../organisms/Header/Header';

const Main = ({ children }) => {
  return (
    <div>
      <Header />
      <div className='main-div'>
        {children}
      </div>
    </div>
  );
};

export default Main;
