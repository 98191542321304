import React from 'react';
import { TableRow, TableCell, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import LanguageIcon from '@mui/icons-material/Language';

const AirportRows = ({ airports, selectedAirports, handleRowClick, type }) => {
  return airports.map((airport) => (
    <TableRow
      key={airport.id}
      onClick={() => handleRowClick(airport, type)}
      selected={selectedAirports.includes(airport.iata_code)}
      style={{ cursor: 'pointer' }}
      sx={{ height: '32px' }}
    >
      <TableCell>{airport.name} ({airport.iata_code})</TableCell>
      <TableCell>{airport.locality?.city}</TableCell>
      <TableCell>
        {airport.home_link ? (
          <Link href={airport.home_link} target="_blank" rel="noopener noreferrer">
            <LinkIcon color="action" />
          </Link>
        ) : (
          <LinkIcon color="disabled" />
        )}
        {airport.wikipedia_link ? (
          <Link href={airport.wikipedia_link} target="_blank" rel="noopener noreferrer">
            <LanguageIcon color="action" />
          </Link>
        ) : (
          <LanguageIcon color="disabled" />
        )}
      </TableCell>
    </TableRow>
  ));
};

export default AirportRows;
