import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

const ProtectedRoute = ({ component: Component }) => {
  const { getToken } = useAuth();
  const token = getToken();

  if (!token) {
    // Redirect to login if there is no token
    return <Navigate to="/login" replace />;
  }

  return <Component />;
};

export default ProtectedRoute;
