import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Box, Snackbar, Alert, Typography, Slider, Checkbox, FormControlLabel, TextField, Rating, Paper, Card, CardContent, CardHeader } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { styled } from '@mui/system';

const useStyles = styled((theme) => ({
  formContainer: {
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    maxWidth: 600,
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(5),
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  card: {
    marginTop: theme.spacing(2),
  },
}));

const bedSizes = ['Twin', 'Full', 'Queen', 'King'];

const TravelFormLodging = forwardRef(({ initialData, onSave }, ref) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...initialData });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useImperativeHandle(ref, () => ({
    submitForm() {
      onSave(formData);
    },
  }));

  const handleToggleChange = (name) => (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      lodgingTravel: {
        ...prevFormData.lodgingTravel,
        [name]: event.target.checked,
      },
    }));
  };

  const handleSliderChange = (name) => (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      lodgingTravel: {
        ...prevFormData.lodgingTravel,
        [name]: newValue,
      },
    }));
  };

  const handleBedSizeChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      lodgingTravel: {
        ...prevFormData.lodgingTravel,
        bedSizes: newValue,
      },
    }));
  };

  const handleRatingChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      lodgingTravel: {
        ...prevFormData.lodgingTravel,
        starRating: newValue,
      },
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ open: false });
  };

  return (
    <Paper elevation={3} className={classes.formContainer}>
      <Typography variant="h5" gutterBottom>Rest Your Head</Typography>
      <Typography variant="body1" gutterBottom>Describe your ideal lodging:</Typography>

      <Card className={classes.card}>
        <CardHeader title="Room Preferences" />
        <CardContent>
          <Box className={classes.formRow}>
            <Typography>Star Rating</Typography>
            <Rating
              value={formData.lodgingTravel.starRating}
              onChange={handleRatingChange}
              precision={0.5}
            />
          </Box>
          <FormControlLabel
            control={<Checkbox checked={formData.lodgingTravel.roomSharing} onChange={handleToggleChange('roomSharing')} />}
            label="Room Sharing"
          />
          <FormControlLabel
            control={<Checkbox checked={formData.lodgingTravel.bedSharing} onChange={handleToggleChange('bedSharing')} />}
            label={`Bed Sharing${formData.lodgingTravel.travelPartner ? ' with Travel Partner' : ''}`}
          />
          <FormControlLabel
            control={<Checkbox checked={formData.lodgingTravel.handicapAccessibility} onChange={handleToggleChange('handicapAccessibility')} />}
            label="Accessible Room"
          />
          <Autocomplete
            multiple
            options={bedSizes}
            value={formData.lodgingTravel.bedSizes}
            onChange={handleBedSizeChange}
            renderInput={(params) => <TextField {...params} label="Bed Size" variant="outlined" />}
            className={classes.formControl}
          />
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader title="Walk Score" />
        <CardContent>
          <Slider
            className={classes.formControl}
            value={formData.lodgingTravel.walkScore}
            onChange={handleSliderChange('walkScore')}
            step={1}
            marks
            min={0}
            max={100}
            valueLabelDisplay="auto"
          />
        </CardContent>
      </Card>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default TravelFormLodging;
