import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Slider,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Card,
  CardContent,
  Link,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import DynamicMap from '../../molecules/DynamicMap/DynamicMap';
import LinkIcon from '@mui/icons-material/Link';
import LanguageIcon from '@mui/icons-material/Language';
import getConfig from '../../../config';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HomeIcon from '@mui/icons-material/Home';

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const StyledMapContainer = styled(Box)(({ theme }) => ({
  height: '400px',
  width: '100%',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const StyledTableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxHeight: 200,
  overflowY: 'auto',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&.selected': {
    backgroundColor: theme.palette.action.selected,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  height: 32,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const generatePopupContent = (title, description) => {
  return `
    <div style="width: 200px; padding: 10px;">
      <h3 style="margin: 0 0 10px;">${title}</h3>
      <p style="margin: 0;">${description}</p>
    </div>
  `;
};

const AirportPicker = ({ location, radius, selectedAirports, onRadiusChange, onAirportSelect }) => {
  const { getToken } = useAuth();
  const { theme } = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [airportMarkers, setAirportMarkers] = useState([]);
  const [airports, setAirports] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  const hometownMarker = {
    lat: location.lat,
    lon: location.lon,
    popup: (
      <div>
        <Typography variant="h9">Home</Typography>
      </div>
    ),
    color: 'red',
    element: (
      <HomeIcon />
    )
  };

  const handleRadiusChange = (event, value) => {
    onRadiusChange(value);
  };

  const handleRowClick = (airport) => {
    onAirportSelect(airport);
  };

  const fetchAirports = async () => {
    if (!radius || !config) {
      console.log('Missing radius or config; skipping fetch.');
      return;
    }

    const token = await getToken();
    const { lat, lon } = location;

    try {
      const url = new URL(`${config.apiBaseUrl}/explore/`);
      url.searchParams.append('category', 'airport');
      url.searchParams.append('radius', radius);
      url.searchParams.append('lat', lat);
      url.searchParams.append('lon', lon);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Fetched airports:', data.data);
        setAirports(data.data || []);
        setAirportMarkers(data.data.map(airport => ({
          lat: airport.location.lat,
          lon: airport.location.lon,
          popup: (
            <Typography variant="h9">{airport.name} ({airport.iata_code})</Typography>
          ),
          color: 'blue',
          element: (
            <LocalAirportIcon />
          )
        })));
      } else {
        throw new Error(data.message || 'Failed to fetch airports');
      }
    } catch (error) {
      console.error('Error fetching airports:', error);
      setSnackbar({ open: true, message: 'Failed to load airports', severity: 'error' });
    }
  };

  useEffect(() => {
    fetchAirports();
  }, [radius, config]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ open: false });
  };

  const renderAirportRows = (airports) => {
    return airports.map((airport, index) => {
      const isSelected = selectedAirports.some((a) => a.id === airport.id);
      return (
        <StyledTableRow
          key={airport.id}
          onClick={() => handleRowClick(airport)}
          className={isSelected ? 'selected' : ''}
          selected={isSelected}
          sx={{ height: '32px' }}  // Adjusting the height of each row
        >
          <StyledTableCell>
            {airport.name} ({airport.iata_code})
          </StyledTableCell>
          <StyledTableCell>{airport.locality?.city}</StyledTableCell>
          <StyledTableCell>
            {airport.home_link ? (
              <Link href={airport.home_link} target="_blank" rel="noopener noreferrer">
                <LinkIcon color="action" />
              </Link>
            ) : (
              <LinkIcon color="disabled" />
            )}
            {airport.wikipedia_link ? (
              <Link href={airport.wikipedia_link} target="_blank" rel="noopener noreferrer">
                <LanguageIcon color="action" />
              </Link>
            ) : (
              <LanguageIcon color="disabled" />
            )}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  };

  return (
    <Card>
      <CardContent>
        <StyledMapContainer>
          <DynamicMap
            latitude={location.lat}
            longitude={location.lon}
            markers={[hometownMarker, ...airportMarkers]}
            isDarkMode={isDarkMode}
            maxZoom={10}
          />
        </StyledMapContainer>

        <StyledBox>
          <Typography gutterBottom>Airport Distance (km)</Typography>
          <Slider
            value={radius}
            onChange={handleRadiusChange}
            valueLabelDisplay="auto"
            step={50}
            marks
            min={50}
            max={500}
          />
        </StyledBox>

        <StyledTableContainer>
          <Table>
            <TableBody>
              {airports.length > 0 ? (
                renderAirportRows(airports)
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>No airports available for this vacation.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};

export default AirportPicker;
