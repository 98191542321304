import React, { useEffect, useState } from 'react';
import { FormGroup, FormControlLabel, AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Drawer, List, ListItem, Avatar, Switch } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { ListItemIcon, ListItemText } from '@mui/material';
import gravatar from 'gravatar';
import { useTheme } from '../../../context/ThemeContext'; // Update this path as necessary
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import getConfig from '../../../config';

const Header = () => {
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const { getToken, logout } = useAuth();
    const [user, setUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const open = Boolean(anchorEl);
    const [config, setConfig] = useState(null);

    useEffect(() => {
        const configData = getConfig();
        setConfig(configData);
    }, []);

    useEffect(() => {
        if (!config) return;

        const fetchUser = async () => {
            const token = getToken();
            if (!token) {
                setUser(null);
                return;
            }

            try {
                const response = await fetch(`${config.apiBaseUrl}/user/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const { data } = await response.json();
                setUser(data);
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        fetchUser();
    }, [config, getToken]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleLogout = () => {
        handleClose();
        logout();
        navigate('/login');
    };

    // Sidebar drawer menu 
    const drawerContent = (
        <List>
            <ListItem button onClick={() => navigate('/')}>
                <ListItemText primary="Vacations" />
            </ListItem>
        </List>
    );

    if (!config) {
        return <div>Loading...</div>;
    }

    return (
        <AppBar position="fixed">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleDrawerOpen}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    sx={{ width: 300, flexShrink: 0, '& .MuiDrawer-paper': { width: 300 } }}
                >
                    {drawerContent}
                </Drawer>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Powertrip: Viator
                </Typography>
                <WbSunnyIcon sx={{ mr: 1, marginRight: 0, fontSize: 18 }} />
                <Switch
                    checked={isDarkMode}
                    onChange={toggleTheme}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <NightsStayIcon sx={{ ml: 1, marginLeft: 0, marginRight: 5, fontSize: 18 }} />
                {user ? (
                    <>
                        <Button color="inherit" onClick={handleMenu}>
                            {user.username}
                            <Avatar src={gravatar.url(user.email, { s: '100', d: 'identicon' })} alt={user.username} sx={{ width: 30, height: 30, marginLeft: 1 }} />
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => navigate('/profile')}>
                                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                <ListItemText primary="Profile" />
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                <ListItemText primary="Logout" />
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
