import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Box, Typography, Tab, Tabs, Card, Paper } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import './LoginBox.css';
import { useTheme } from '@mui/material/styles';
import getConfig from '../../../config';

function LoginBox() {
    const theme = useTheme()
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [tab, setTab] = useState(0); // 0 for login, 1 for register
    const navigate = useNavigate();
    const [config, setConfig] = useState(null);
  
    useEffect(() => {
      const configData = getConfig();
      setConfig(configData);
    }, []);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${config.apiBaseUrl}/auth/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
            const data = await response.json();
            if (response.ok) {
                login(data.data);  // Storing the tokens
                console.log('Login Successful:', data);
                navigate('/');
            } else {
                throw new Error(data.message || "Unknown error");
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${config.apiBaseUrl}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, email })
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Registration Successful:', data);
        navigate('/onboard'); // Or navigate to a confirmation page or login page
      } else {
        throw new Error(data.message || "Unknown error");
      }
    } catch (error) {
      console.error('Error registering:', error);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Card className='login-box' maxWidth="xs">
      <Tabs value={tab} onChange={handleChangeTab} centered>
        <Tab label="Login" />
        <Tab label="Register" />
      </Tabs>
      {tab === 0 && (
        <LoginForm
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
        />
      )}
      {tab === 1 && (
        <RegisterForm
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          email={email}
          setEmail={setEmail}
          handleRegister={handleRegister}
        />
      )}
    </Card>
  );
}

function LoginForm({ username, setUsername, password, setPassword, handleLogin }) {
  return (
    <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="login-username"
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="login-password"
        autoComplete="current-password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
    </Box>
  );
}

function RegisterForm({ username, setUsername, password, setPassword, email, setEmail, handleRegister }) {
  return (
    <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="register-email"
        label="Email"
        name="email"
        autoComplete="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="register-username"
        label="Username"
        name="username"
        autoComplete="username"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="register-password"
        autoComplete="new-password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Register
      </Button>
    </Box>
  );
}

export default LoginBox;
