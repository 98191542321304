import React, { useState, useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext'; // Adjust the import path as needed
import getConfig from '../../../config';
import { styled } from '@mui/system';
import PlaceCard from '../../organisms/PlaceCard/PlaceCard';

const PlacesContainer = styled('div')({
  display: 'flex',
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  padding: '8px 0',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const Places = ({ vacationObj, bounds, center, onPlacesUpdate }) => {
  const [vacation, setVacation] = useState(vacationObj);
  const [mapBounds, setMapBounds] = useState(bounds);
  const [mapCenter, setMapCenter] = useState(center);
  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [wasExpanded, setWasExpanded] = useState(false);
  const { getToken } = useAuth();
  const [config, setConfig] = useState(null);
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState('');
  const containerRef = useRef(null);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const loadApiKey = async () => {
      if (!config) return;

      const token = await getToken();
      if (!token) {
        console.error('Token not found');
        return;
      }

      try {
        const response = await axios.get(`${config.apiBaseUrl}/external/google/maps`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setGoogleMapsApiKey(response.data.api_key);
      } catch (error) {
        console.error('Error fetching the Google Maps API key:', error);
      }
    };

    if (config) {
      loadApiKey();
    }
  }, [config, getToken]);

  useEffect(() => {
    setVacation(vacationObj);
  }, [vacationObj]);

  useEffect(() => {
    setMapBounds(bounds);
  }, [bounds]);

  useEffect(() => {
    setMapCenter(center);
  }, [center]);

  const fetchPlaces = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await axios.get(`${config.apiBaseUrl}/explore/`, {
        params: {
          category: 'places',
          radius: 5,
          lat: mapCenter.lat,
          lon: mapCenter.lng,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      console.debug('Places response:', response.data.data);
      const placesWithImageUrls = response.data.data.map((place) => ({
        ...place,
        imageUrls: place.photos ? place.photos.map((photo) => `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photo.photo_reference}&key=${googleMapsApiKey}`) : []
      }));
      setPlaces(placesWithImageUrls);
      onPlacesUpdate(placesWithImageUrls); // Call the callback with the updated places
    } catch (error) {
      console.error('API Request Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
    if (!expanded) {
      fetchPlaces();
    } else if (wasExpanded) {
      onPlacesUpdate([]); // Hide markers when the accordion is collapsed
    }
    setWasExpanded(!expanded);
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Places</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box position="relative">
          <IconButton
            onClick={scrollLeft}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <PlacesContainer ref={containerRef}>
            {loading ? (
              Array.from({ length: 20 }).map((_, index) => (
                <PlaceCard
                  key={index}
                  title=""
                  subtitle=""
                  thirdLine=""
                  rating={null}
                  imageUrls={[]}
                />
              ))
            ) : (
              places.map((place, index) => (
                <PlaceCard
                  key={index}
                  title={place.name}
                  subtitle={place.address}
                  rating={place.rating}
                  imageUrls={place.imageUrls}
                />
              ))
            )}
          </PlacesContainer>
          <IconButton
            onClick={scrollRight}
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Places;
