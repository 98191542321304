import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import moment from 'moment-timezone';

const WaitTime = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
  textAlign: 'center',
}));

const FlightOffers = ({ offers, expandedOffer, handleOfferClick }) => {
  const [visibleRows, setVisibleRows] = useState(20);

  const getSliceName = (index, totalSlices) => {
    if (index === 0) return 'Departing';
    if (index === totalSlices - 1) return 'Return';
    return 'Commuter';
  };

  const calculateWaitTime = (previousArrival, nextDeparture) => {
    const arrival = moment(previousArrival);
    const departure = moment(nextDeparture);
    const waitTime = moment.duration(departure.diff(arrival));
    return `${Math.floor(waitTime.asHours())}h ${waitTime.minutes()}m`;
  };

  const calculateSegmentDuration = (segment) => {
    const departure = moment(segment.departing_at);
    const arrival = moment(segment.arriving_at);
    const duration = moment.duration(arrival.diff(departure));
    return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
  };

  const calculateTotalTripDuration = (slices) => {
    const departingRoute = slices[0];
    const returningRoute = slices[slices.length - 1];

    const departingTime = moment(departingRoute.segments[0].departing_at);
    const arrivingTime = moment(departingRoute.segments[departingRoute.segments.length - 1].arriving_at);

    console.log('departingTime:', departingTime.utc() );
    console.log('arrivingTime:', arrivingTime.utc());

    const duration = moment.duration(arrivingTime.diff(departingTime));
    return duration.asHours();
  };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
  };

  const getLayoverText = (segments) => {
    const totalSegments = segments.length;
    if (totalSegments === 1) return 'Nonstop';
    if (totalSegments === 2) return '1 layover';
    return `${totalSegments - 1} layovers`;
  };

  const getMarketingCarriers = (segments) => {
    const carriers = [...new Set(segments.map(segment => segment.marketing_carrier.name))];
    return carriers.join(' - ');
  };

  const loadMoreRows = () => {
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 20);
  };

  if (!offers || !offers.length) {
    return <Typography>No flight offers available.</Typography>;
  }

  return (
    <>
      {offers.slice(0, visibleRows).map((offer) => {
        if (!offer.slices || !offer.slices.length) return null;

        const firstSliceSegments = offer.slices[0].segments || [];
        const layoverText = getLayoverText(firstSliceSegments);
        const totalTripDuration = calculateTotalTripDuration(offer.slices);

        return (
          <React.Fragment key={offer.slices[0].id}>
            <TableRow onClick={() => handleOfferClick(offer.slices[0].id)} sx={{ cursor: 'pointer' }}>
              <TableCell>
                <IconButton size="small">
                  <ExpandMoreIcon />
                </IconButton>
              </TableCell>
              <TableCell>{getMarketingCarriers(firstSliceSegments)}</TableCell>
              <TableCell>{layoverText}</TableCell>
              <TableCell>{totalTripDuration}</TableCell>
              {['economy', 'premium_economy', 'business', 'first'].map(cabinClass => (
                offer.cabin_classes[cabinClass] ? (
                  <TableCell key={cabinClass}>
                    {formatCurrency(offer.cabin_classes[cabinClass].total_amount, offer.cabin_classes[cabinClass].total_currency)}
                  </TableCell>
                ) : (
                  <TableCell key={cabinClass}>N/A</TableCell>
                )
              ))}
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                <Collapse in={expandedOffer === offer.slices[0].id} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    {offer.slices.map((slice, sliceIndex) => (
                      <Box key={slice.id} marginBottom={2}>
                        <Typography variant="h6" gutterBottom component="div">
                          {getSliceName(sliceIndex, offer.slices.length)}
                        </Typography>
                        <Table size="small" aria-label="segments">
                          <TableHead>
                            <TableRow>
                              <TableCell>Origin</TableCell>
                              <TableCell>Destination</TableCell>
                              <TableCell>Departure</TableCell>
                              <TableCell>Arrival</TableCell>
                              <TableCell>Aircraft</TableCell>
                              <TableCell>Duration</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {slice.segments && slice.segments.map((segment, segmentIndex) => (
                              <React.Fragment key={segment.id}>
                                {segmentIndex > 0 && (
                                  <TableRow>
                                    <TableCell colSpan={6}>
                                      <WaitTime>
                                        Wait Time: {calculateWaitTime(
                                          slice.segments[segmentIndex - 1].arriving_at,
                                          segment.departing_at
                                        )}
                                      </WaitTime>
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                  <TableCell>{segment.origin.name}</TableCell>
                                  <TableCell>{segment.destination.name}</TableCell>
                                  <TableCell>{moment(segment.departing_at).format('LLL')}</TableCell>
                                  <TableCell>{moment(segment.arriving_at).format('LLL')}</TableCell>
                                  <TableCell>{segment?.aircraft?.name}</TableCell>
                                  <TableCell>{calculateSegmentDuration(segment)}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
      {visibleRows < offers.length && (
        <TableRow>
          <TableCell colSpan={8} align="center">
            <Button onClick={loadMoreRows} variant="contained" color="primary">
              Show More
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default FlightOffers;
