import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { calculateAverageDistance } from './distanceUtils';
import { useAuth } from '../../../context/AuthContext'; // Adjust the import path as needed
import AirportRows from './AirportRows';
import FlightOffers from './FlightOffers';

const Flights = ({ topAirports, vacationAirports }) => {
  const [selectedDeparting, setSelectedDeparting] = useState([]);
  const [selectedArriving, setSelectedArriving] = useState([]);
  const [expandedOffer, setExpandedOffer] = useState(null);
  const [flightOffers, setFlightOffers] = useState([]);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchFlightOffers = async () => {
      if (selectedDeparting.length && selectedArriving.length) {
        try {
          console.log('Fetching flight offers...');
          console.log('selectedDeparting:', selectedDeparting);
          console.log('selectedArriving:', selectedArriving);
          const token = await getToken();
          if (!token) {
            console.error('Token not found');
            return;
          }
          const departing = [...new Set(selectedDeparting)].join(',');
          const arriving = [...new Set(selectedArriving)].join(',');
          const response = await fetch(
            `http://local.powertrip.me:5000/flights/search?departure_airports=${departing}&arrival_airports=${arriving}&departure_date=2024-06-01&return_date=2024-06-15&cabin_class=first`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error fetching flight offers: ${response.status} ${errorText}`);
          }
          const data = await response.json();
          console.log('Flight offers:', data.data);
          setFlightOffers(data.data);
        } catch (error) {
          console.error(error.message);
        }
      }
    };

    const debounceFetch = setTimeout(fetchFlightOffers, 3000);
    return () => clearTimeout(debounceFetch);
  }, [selectedDeparting, selectedArriving]);

  const handleRowClick = (airport, type) => {
    if (type === 'departing') {
      console.log('Selected departing airport:', airport);
      setSelectedDeparting((prev) => {
        const isSelected = prev.includes(airport.iata_code);
        return isSelected ? prev.filter((id) => id !== airport.iata_code) : [...prev, airport.iata_code];
      });
    } else {
      setSelectedArriving((prev) => {
        const isSelected = prev.includes(airport.iata_code);
        return isSelected ? prev.filter((id) => id !== airport.iata_code) : [...prev, airport.iata_code];
      });
    }
  };

  const handleOfferClick = (offerId) => {
    setExpandedOffer(expandedOffer === offerId ? null : offerId);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Flights</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper style={{ width: '100%', marginBottom: '16px', marginTop: '16px', padding: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Recommended Departing Airports</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ height: '32px' }}>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Links</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topAirports.length > 0 ? (
                      <AirportRows
                        airports={topAirports}
                        selectedAirports={selectedDeparting}
                        handleRowClick={handleRowClick}
                        type="departing"
                      />
                    ) : (
                      <TableRow sx={{ height: '32px' }}>
                        <TableCell colSpan={3}>No airports available for this vacation.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Recommended Arriving Airports</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ height: '32px' }}>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Links</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vacationAirports.length > 0 ? (
                      <AirportRows
                        airports={vacationAirports}
                        selectedAirports={selectedArriving}
                        handleRowClick={handleRowClick}
                        type="arriving"
                      />
                    ) : (
                      <TableRow sx={{ height: '32px' }}>
                        <TableCell colSpan={3}>No airports available for this vacation.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
        {calculateAverageDistance(topAirports, vacationAirports) !== null && (
          <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
            Average Distance Between Airports: {calculateAverageDistance(topAirports, vacationAirports)} miles
          </Typography>
        )}
        <Typography variant="h6" style={{ marginTop: '16px' }}>Flight Offers</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Airline</TableCell>
                <TableCell>Layovers</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Economy</TableCell>
                <TableCell>Premium Economy</TableCell>
                <TableCell>Business</TableCell>
                <TableCell>First</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flightOffers?.data?.length > 0 ? (
                <FlightOffers offers={flightOffers.data} expandedOffer={expandedOffer} handleOfferClick={handleOfferClick} />
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>No flight offers available.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default Flights;
