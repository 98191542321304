import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { useAuth } from '../../../context/AuthContext';
import getConfig from '../../../config';

const AirlineAutocomplete = ({ selectedAirlines, setSelectedAirlines }) => {
  const { getToken } = useAuth();
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const configData = getConfig();
    setConfig(configData);
  }, []);

  const fetchAirlines = async (query) => {
    const token = await getToken();
    if (!token) return [];

    try {
      const response = await fetch(`${config.apiBaseUrl}/airlines/?name=${query}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch airlines');
      }

      const { data } = await response.json();
      console.log('Airlines fetched successfully', data);
      return data;
    } catch (error) {
      console.error('Error fetching airlines:', error.message);
      return [];
    }
  };

  const debouncedFetchAirlines = debounce(async (query) => {
    if (query.length > 2) {
      const results = await fetchAirlines(query);
      setAirlineOptions(results);
    }
  }, 300);

  return (
    <Autocomplete
      multiple
      options={airlineOptions}
      getOptionLabel={(option) => {
        return `${option.name}, ${option.country?.iso2}`;
    }}
      value={selectedAirlines}
      onChange={(event, newValue) => {
        setSelectedAirlines(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Preferred Airline"
          margin="normal"
          onChange={(e) => debouncedFetchAirlines(e.target.value)}
        />
      )}
    />
  );
};

export default AirlineAutocomplete;
