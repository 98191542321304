import React, { useState, useEffect } from 'react';
import { Typography, Modal, Box, TextField, FormControl, Button, Snackbar, Alert } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useAuth } from '../../../context/AuthContext';
import LocalityAutocomplete from '../../molecules/LocalityAutocomplete/LocalityAutocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import getConfig from '../../../config';

function NewVacationModal({ isOpen, onClose, onVacationCreated }) {
  const { getToken } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    description: ''
  });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [config, setConfig] = useState(null);

  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
    };

    loadConfig();
  }, []);

  const handleCloseModal = () => {
    onClose();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    // Validate that all fields are filled in
    if (!startDate || !endDate || selectedLocation.length === 0 || !formData.title || !formData.description) {
      setSnackbarMessage('All fields must be filled in, including dates, title, description, and locations.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Extract IDs from the selected localities
    const localityIds = selectedLocation.map((locality) => locality.id);

    const token = await getToken();
    if (!config) {
      setSnackbarMessage('Configuration not loaded');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const url = `${config.apiBaseUrl}/vacations/`;
    const payload = {
      title: formData.title,
      description: formData.description,
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
      localities: localityIds
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to create new vacation');
      }
      const createdVacation = await response.json();
      onVacationCreated(createdVacation); // Call the callback with the new vacation

      setSnackbarMessage('Vacation created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      handleCloseModal();
    } catch (error) {
      setSnackbarMessage(`Error creating vacation: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create New Vacation
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />
            <TextField
              sx={{ mt: 1 }}
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label="End Date"
                sx={{ mt: 1 }}
                value={endDate}
                onChange={setEndDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <LocalityAutocomplete onSelect={(newValue) => setSelectedLocation(newValue)} />
          </FormControl>
          <Button onClick={handleSubmit} sx={{ mt: 2, width: "100%" }} variant="contained">Submit</Button>
        </Box>
      </Modal>
      {/* Snackbar to display feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default NewVacationModal;
