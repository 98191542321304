import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Skeleton } from '@mui/material';

const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'opacity 0.3s ease',
  opacity: 0,
});

const LazyImage = ({ src, alt, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const imgRef = useRef(null);
  const imgElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  const handleImageLoad = () => {
    setLoading(false);
    if (imgElementRef.current) {
      imgElementRef.current.style.opacity = 1;
    }
  };

  return (
    <ImageContainer ref={imgRef}>
      {loading && <Skeleton variant="rectangular" width="100%" height="100%" />}
      {isVisible && (
        <StyledImage
          ref={imgElementRef}
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          {...props}
        />
      )}
    </ImageContainer>
  );
};

export default LazyImage;
