import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Vacations from './components/pages/Vacations/Vacations';
import Vacation from './components/pages/Vacation/Vacation';
import Login from './components/pages/Login/Login';
import Profile from './components/pages/Profile/Profile';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './ProtectedRoute';
import './index.css';
import { ThemeProvider } from './context/ThemeContext'; // Adjust the import path as necessary
import Onboarding from './components/pages/Onboarding/Onboarding';
import getConfig from './config';
import log from 'loglevel';

const App = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const configData = getConfig();
    setConfig(configData);
    if (config) {
      log.debug(`Using config for environment: ${config?.environment}`);
    }
  }, [config?.environment]);


  if (!config) {
    return <div>Loading configuration...</div>;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/onboard" element={<Onboarding />} />
          <Route path="/" element={<ProtectedRoute component={Vacations} />} />
          <Route path="/vacations/:vacationId" element={<ProtectedRoute component={Vacation} />} />
          <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
