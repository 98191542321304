import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  CircularProgress,
  Toolbar,
  Button,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import Main from '../../templates/Main/Main';
import DynamicMap from '../../molecules/DynamicMap/DynamicMap';
import VacationOverview from './VacationOverview';
import Flights from './Flights';
import Hotels from './Hotels';
import Dining from './Dining';
import ThingsToDo from './ThingsToDo';
import Entertainment from './Entertainment';
import TravelerProfiles from './TravelerProfiles';
import Places from './Places';
import Calendar from './Calendar';
import AirportPickerModal from '../../organisms/AirportPicker/AirportPickerModal';
import TravelProfileModal from '../../organisms/TravelProfileModal/TravelProfileModal';
import getConfig from '../../../config';
import LocalAirportIcon from '@mui/icons-material/LocalAirport'; // Import the LocalAirport icon
import PlaceIcon from '@mui/icons-material/Place'; // Import an icon for places
import PlaceCard from './PlaceCard'; // Adjust the import path as needed
import log from 'loglevel';
import axios from 'axios';

log.setLevel('debug'); // Set the logging level to debug

function VacationDetails() {
  const { vacationId } = useParams();
  const [vacation, setVacation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAirportPicker, setOpenAirportPicker] = useState(false);
  const [radius, setRadius] = useState(100);
  const [selectedAirports, setSelectedAirports] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const { getToken, currentUser } = useAuth();
  const { theme } = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [openTravelProfile, setOpenTravelProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [config, setConfig] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lon: 0 });
  const [bounds, setBounds] = useState(null);
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState('');

  useEffect(() => {
    const loadConfig = async () => {
      const configData = await getConfig();
      setConfig(configData);
      log.debug(`Using config for environment: ${configData.environment}`);
    };

    loadConfig();
  }, []);

  const getTopAirports = (travelProfiles) => {
    if (!travelProfiles || travelProfiles.length === 0) return [];

    const airportCounts = {};
    const airportIdsByUser = travelProfiles?.map((profile) =>
      profile.airports.filter((airport) => airport && typeof airport === 'object' && airport.id).map((airport) => airport.id)
    );

    airportIdsByUser.forEach((airportIds) => {
      airportIds.forEach((id) => {
        airportCounts[id] = (airportCounts[id] || 0) + 1;
      });
    });

    const totalUsers = travelProfiles.length;
    const commonAirports = Object.keys(airportCounts).filter((id) => airportCounts[id] === totalUsers);

    if (commonAirports.length > 0) {
      return commonAirports
        .map((id) => travelProfiles[0].airports.find((airport) => airport.id === id))
        .filter((airport) => airport);
    }

    const topAirports = Object.entries(airportCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([id]) => travelProfiles[0].airports.find((airport) => airport.id === id))
      .filter((airport) => airport);

    return topAirports.length > 0 ? topAirports : travelProfiles[0].airports.slice(0, 5);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = await getToken();
      if (!token) {
        setUser(null);
        return;
      }

      try {
        const response = await fetch(`${config.apiBaseUrl}/user/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const { data } = await response.json();
        setUser(data);
      } catch (error) {
        log.error('Error fetching user data:', error.message);
      }
    };

    if (config) {
      fetchUser();
    }
  }, [config, getToken]);

  const fetchVacationDetails = async () => {
    setLoading(true);
    const token = await getToken();
    if (!token) {
      setError('Token not found');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/vacations/${vacationId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Failed to fetch vacation details');
      const data = await response.json();
      setVacation(data.data);
      if (data.data.airports.length === 0) {
        setOpenAirportPicker(true);
      }

      const topAirports = getTopAirports(data.data.travel_profiles || []);
      const newMarkers = [
        ...data.data.airports.map((airport) => ({
          lat: airport?.location?.lat,
          lon: airport?.location?.lon,
          name: airport.name,
          popup: `${airport.name} (${airport.iata_code})`,
          color: 'blue',
          element: (
            <LocalAirportIcon />
          )
        })),
        ...data.data.localities.map((locality) => ({
          lat: locality?.location?.lat,
          lon: locality?.location?.lon,
          name: locality.name,
          popup: `${locality.city}, ${locality.country.english_formal}`,
          color: 'red',
         })),
        ...topAirports.map((airport) => ({
          lat: airport?.location?.lat,
          lon: airport?.location?.lon,
          name: airport?.name,
          popup: `${airport.name} (${airport.iata_code})`,
          color: 'green',
          element: (
            <LocalAirportIcon />
          )
        })),
      ];

      setMarkers(newMarkers);

      for (let x in data.data.travel_profiles) {
        if (data.data.travel_profiles[x].user.id === user.id) {
          setSelectedProfile(data.data.travel_profiles[x]);
        }
      }

      const userProfile = data.data.travel_profiles.find((profile) => profile.user.id === currentUser.id);
      setSelectedProfile(userProfile);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching vacation details:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (config) {
      fetchVacationDetails();
    }
  }, [config, vacationId, getToken, currentUser, user]);

  useEffect(() => {
    const loadApiKey = async () => {
      if (!config) return;

      const token = await getToken();
      if (!token) {
        console.error('Token not found');
        return;
      }

      try {
        const response = await axios.get(`${config.apiBaseUrl}/external/google/maps`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setGoogleMapsApiKey(response.data.api_key);
      } catch (error) {
        console.error('Error fetching the Google Maps API key:', error);
      }
    };

    if (config) {
      loadApiKey();
    }
  }, [config, getToken]);

  const handleAirportPickerClose = () => {
    setOpenAirportPicker(false);
  };

  const handleAirportPickerSave = async (selectedAirports) => {
    const token = await getToken();
    if (!token) {
      setError('Token not found');
      return;
    }

    selectedAirports = selectedAirports.map((airport) => airport.id);

    try {
      const response = await fetch(`${config.apiBaseUrl}/vacations/${vacationId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ airports: selectedAirports }),
      });

      if (!response.ok) {
        throw new Error('Failed to update airports');
      }
      const updatedVacation = await response.json();
      setVacation((prev) => ({ ...prev, airports: updatedVacation.data.airports }));

      const newMarkers = selectedAirports.map((airport) => ({
        lat: airport?.location?.lat,
        lon: airport?.location?.lon,
        name: airport?.name,
        iata_code: airport?.iata_code,
        iconUrl: 'https://icon-park.com/imagefiles/location_pin_sphere_red.png',
      }));
      setMarkers((prevMarkers) => [...prevMarkers, ...newMarkers]);

      setOpenAirportPicker(false);
      setSnackbar({ open: true, message: 'Airports updated successfully', severity: 'success' });
    } catch (error) {
      setError(error.message);
      log.error('Failed to update airports:', error.message);
      setSnackbar({ open: true, message: 'Failed to update airports', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ open: false });
  };

  const handleRadiusChange = (newRadius) => {
    setRadius(newRadius);
  };

  const handleAirportSelect = (airport) => {
    setSelectedAirports((prev) => {
      const isSelected = prev.some((a) => a.id === airport.id);
      if (isSelected) {
        return prev.filter((a) => a.id !== airport.id);
      } else {
        return [...prev, airport];
      }
    });
  };

  const handleMapBoundsChange = (newBounds, newCenter) => {
    setBounds(newBounds);
    setCenter(newCenter);
  };

  const handlePlacesUpdate = (places) => {
    log.debug('Places updated:', places);
    const placeMarkers = places.map((place) => ({
      lat: place.location.lat,
      lon: place.location.lon,
      name: place.name,
      popup: (
        <PlaceCard
          title={place.name}
          subtitle={place.address}
          rating={place.rating}
          imageUrls={place.photos ? place.photos.map((photo) => `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photo.photo_reference}&key=${googleMapsApiKey}`) : []}
        />
      ),
      color: 'purple',
    }));
    setMarkers((prevMarkers) => {
      // Filter out old place markers
      const oldMarkers = prevMarkers.filter(marker => marker.color !== 'purple');
      return [...oldMarkers, ...placeMarkers];
    });
  };

  const handleThingsToDoUpdate = (thingsToDo) => {
    log.debug('ThingsToDo updated:', thingsToDo);
    const thingsToDoMarkers = thingsToDo.map((thing) => ({
      lat: thing.location.lat,
      lon: thing.location.lon,
      name: thing.name,
      popup: (
        <PlaceCard
          title={thing.name}
          subtitle={thing.address}
          rating={thing.rating}
          imageUrls={thing.photos ? thing.photos.map((photo) => `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photo.photo_reference}&key=${googleMapsApiKey}`) : []}
        />
      ),
      color: 'indigo',
    }));
    setMarkers((prevMarkers) => {
      // Filter out old things to do markers
      const oldMarkers = prevMarkers.filter(marker => marker.color !== 'indigo');
      return [...oldMarkers, ...thingsToDoMarkers];
    });
  };

  const handleEntertainmentUpdate = (entertainments) => {
    log.debug('Entertainment updated:', entertainments);
    const entertainmentMarkers = entertainments.map((entertainment) => ({
      lat: entertainment.location.lat,
      lon: entertainment.location.lon,
      name: entertainment.name,
      popup: (
        <PlaceCard
          name={entertainment.name}
          address={entertainment.address}
          rating={entertainment.rating}
          imageUrls={entertainment.photos ? entertainment.photos.map((photo) => `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photo.photo_reference}&key=${googleMapsApiKey}`) : []}
        />
      ),
      color: 'orange',
    }));
    setMarkers((prevMarkers) => {
      // Filter out old entertainment markers
      const oldMarkers = prevMarkers.filter(marker => marker.color !== 'indigo');
      return [...oldMarkers, ...entertainmentMarkers];
    });
  };  

  const handleTravelProfileSave = async (updatedProfile) => {
    if (!updatedProfile.travel_partner) {
      delete updatedProfile.travel_partner;
    }
    updatedProfile = {
      ...updatedProfile,
      vacation: vacationId,
    };
    const token = await getToken();
    if (!token) {
      setError('Token not found');
      return;
    }

    const isGlobalProfile = !updatedProfile.vacation;

    if (isGlobalProfile) {
      delete updatedProfile.id;
      delete updatedProfile.vacation;
      updatedProfile = {
        ...updatedProfile,
        vacation: vacationId,
      };
    }

    try {
      const response = await fetch(
        `${config.apiBaseUrl}/travel_profiles/${isGlobalProfile ? '' : selectedProfile.id}`,
        {
          method: isGlobalProfile ? 'POST' : 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedProfile),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to ${isGlobalProfile ? 'create' : 'update'} travel profile`);
      }

      const updatedTravelProfile = await response.json();
      setVacation((prev) => {
        const updatedProfiles = isGlobalProfile
          ? [...prev.travel_profiles, updatedTravelProfile.data]
          : prev.travel_profiles.map((profile) =>
              profile.id === updatedProfile.id ? updatedTravelProfile.data : profile
            );
        return { ...prev, travel_profiles: updatedProfiles };
      });

      setOpenTravelProfile(false);
      setSnackbar({
        open: true,
        message: `Travel profile ${isGlobalProfile ? 'created' : 'updated'} successfully`,
        severity: 'success',
      });
      fetchVacationDetails(); // Refresh the data on the page
    } catch (error) {
      setError(error.message);
      log.error(`Failed to ${isGlobalProfile ? 'create' : 'update'} travel profile`, error.message);
      setSnackbar({ open: true, message: `Failed to ${isGlobalProfile ? 'create' : 'update'} travel profile`, severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const location = vacation?.localities?.[0]?.location || { lat: 0, lon: 0 };

  return (
    <div>
      <Toolbar
        sx={{
          width: '100%',
          backgroundColor: 'secondary.main',
          position: 'fixed',
          top: 65,
          zIndex: 50,
          maxHeight: 15,
        }}
      >
        <Button edge="start" color="inherit" aria-label="menu">
          <TravelExploreIcon />
          <Typography variant="h10">Explore</Typography>
        </Button>
        <Button edge="start" color="inherit" aria-label="menu">
          <PersonAddIcon />
          <Typography variant="h10">Add Travelers</Typography>
        </Button>
        <Button edge="start" color="inherit" aria-label="menu">
          <AddLocationAltIcon />
          <Typography variant="h10">Add Destination</Typography>
        </Button>
        <Button edge="start" color="inherit" aria-label="menu" onClick={() => setOpenTravelProfile(true)}>
          <Typography variant="h10">Travel Profile</Typography>
        </Button>
      </Toolbar>
      <Main>
        {vacation ? (
          <div style={{ marginTop: '70px' }}>
            <VacationOverview vacation={vacation} />
            <DynamicMap
              latitude={location.lat}
              longitude={location.lon}
              isDarkMode={isDarkMode}
              markers={markers}
              onBoundsChanged={handleMapBoundsChange}
            />
            <Calendar vacationObj={vacation} />
            <Flights topAirports={getTopAirports(vacation.travel_profiles || [])} vacationAirports={vacation.airports} />
            <Hotels />
            <Dining />
            <Entertainment vacationObj={vacation} center={center} onEntertainmentUpdate={handleEntertainmentUpdate} />
            <ThingsToDo vacationObj={vacation} center={center} bounds={bounds} onThingsToDoUpdate={handleThingsToDoUpdate} />
            <Places vacationObj={vacation} center={center} bounds={bounds} onPlacesUpdate={handlePlacesUpdate} />
            <TravelerProfiles
              travelProfiles={vacation.travel_profiles}
              onEditProfile={(profile) => {
                if (profile.user.id === currentUser?.id) {
                  setSelectedProfile(profile);
                  setOpenTravelProfile(true);
                } else {
                  setSnackbar({ open: true, message: 'You can only edit your own profile.', severity: 'error' });
                }
              }}
            />
          </div>
        ) : (
          <Typography variant="body1">Vacation not found.</Typography>
        )}
      </Main>
      <AirportPickerModal
        open={openAirportPicker}
        onClose={handleAirportPickerClose}
        onSave={handleAirportPickerSave}
        location={location}
        radius={radius}
        selectedAirports={selectedAirports}
        onRadiusChange={handleRadiusChange}
        onAirportSelect={handleAirportSelect}
        disableExit={true}
      />
      <TravelProfileModal
        open={openTravelProfile}
        onClose={() => setOpenTravelProfile(false)}
        travelProfile={selectedProfile}
        onSave={handleTravelProfileSave}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default VacationDetails;
