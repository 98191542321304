import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TravelAdvisory from '../../organisms/TravelAdvisory/TravelAdvisory';

const VacationOverview = ({ vacation }) => (
  <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">{vacation.title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="body1">{vacation.description}</Typography>
    </AccordionDetails>
    <TravelAdvisory countryCode={vacation.localities[0].country.iso2} />
  </Accordion>
);

export default VacationOverview;
