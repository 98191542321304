import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Avatar, Modal, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timelinePlugin from '@fullcalendar/timeline';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { formatDate } from '@fullcalendar/core';
import gravatar from 'gravatar';
import { styled } from '@mui/system';

const ResourceContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '120px', // Adjust the width as needed
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const ResourceText = styled(Typography)({
  marginLeft: '8px',
});

const GroupEvent = styled('div')(({ theme, height }) => ({
  backgroundColor: theme.palette.secondary.light,
  borderColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: '2px 4px',
  borderRadius: '4px',
  height: height, // Set the height dynamically
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Calendar = ({ vacationObj }) => {
  const [vacation, setVacation] = useState(vacationObj);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setVacation(vacationObj);
  }, [vacationObj]);

  useEffect(() => {
    const startDate = vacation.start_date;
    const endDate = vacation.end_date;

    // Example dynamic events based on vacation dates
    const initialEvents = vacation.localities.flatMap((locality, index) => {
      return vacation.travel_profiles.map((travel_profile) => ({
        id: `${travel_profile.user.id}-${index}`,
        title: `Visit ${locality.city}`,
        start: startDate,
        end: endDate,
        resourceId: travel_profile.user.id
      }));
    });

    // Group events by locality and time
    const groupedEvents = [];
    const eventMap = {};

    initialEvents.forEach(event => {
      const key = `${event.title}-${event.start}-${event.end}`;
      if (!eventMap[key]) {
        eventMap[key] = {
          ...event,
          resourceIds: [event.resourceId],
          className: 'group-event'
        };
      } else {
        eventMap[key].resourceIds.push(event.resourceId);
      }
    });

    for (const key in eventMap) {
      groupedEvents.push(eventMap[key]);
    }

    setEvents(groupedEvents);
  }, [vacation]);

  // Example dynamic resources based on user data
  const resources = vacation.travel_profiles.map(profile => ({
    id: profile.user.id,
    title: profile.user.username,
    extendedProps: {
      avatar: gravatar.url(profile.user.email, { s: '100', d: 'identicon' })
    }
  }));

  const userCount = resources.length;
  const calendarHeight = `${(userCount * 50) + 165}px`;

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const handleEventDrop = (eventDropInfo) => {
    const updatedEvents = events.map(event => 
      event.id === eventDropInfo.event.id
        ? {
            ...event,
            start: eventDropInfo.event.start,
            end: eventDropInfo.event.end
          }
        : event
    );
    setEvents(updatedEvents);
  };

  const handleEventResize = (eventResizeInfo) => {
    const updatedEvents = events.map(event => 
      event.id === eventResizeInfo.event.id
        ? {
            ...event,
            start: eventResizeInfo.event.start,
            end: eventResizeInfo.event.end
          }
        : event
    );
    setEvents(updatedEvents);
  };

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Calendar</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FullCalendar
            plugins={[interactionPlugin, timelinePlugin, resourceTimelinePlugin]}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            initialView="resourceTimeline"
            views={{
              resourceTimeline: {
                type: 'resourceTimeline',
                duration: { days: Math.ceil((new Date(vacation.end_date) - new Date(vacation.start_date)) / (1000 * 60 * 60 * 24)) + 1 },
                buttonText: 'Vacation'
              }
            }}
            headerToolbar={{
              left: '',
              center: 'title',
              right: ''
            }}
            initialDate={vacation.start_date}
            events={events}
            resources={resources}
            height={calendarHeight}
            resourceAreaWidth="150px"
            resourceAreaHeaderContent="Travelers"
            resourceLabelContent={renderResourceContent}
            eventContent={renderEventContent}
            eventClick={handleEventClick} // Handle event click
            editable={true} // Enable drag and drop
            eventResizableFromStart={true} // Allow resizing from start
            eventDrop={handleEventDrop} // Handle event drop
            eventResize={handleEventResize} // Handle event resize
          />
        </AccordionDetails>
      </Accordion>

      <Modal
        open={Boolean(selectedEvent)}
        onClose={handleCloseModal}
        aria-labelledby="event-modal-title"
        aria-describedby="event-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          {selectedEvent && (
            <>
              <Typography id="event-modal-title" variant="h6" component="h2">
                {selectedEvent.title}
              </Typography>
              <Typography id="event-modal-description" sx={{ mt: 2 }}>
                Start: {formatDate(selectedEvent.start, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
              </Typography>
              <Typography id="event-modal-description" sx={{ mt: 2 }}>
                End: {formatDate(selectedEvent.end, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

const renderResourceContent = (resourceInfo) => {
  return (
    <ResourceContent>
      <Avatar src={resourceInfo.resource.extendedProps.avatar} />
      <ResourceText variant="body2">
        {resourceInfo.resource.title}
      </ResourceText>
    </ResourceContent>
  );
};

const renderEventContent = (eventInfo) => {
  const isGroupEvent = eventInfo.event.classNames.includes('group-event');
  
  if (isGroupEvent && eventInfo.event.extendedProps.resourceIds) {
    const resourceIds = eventInfo.event.extendedProps.resourceIds;
    const height = `${resourceIds.length * 50}px`;

    return (
      <GroupEvent height={height}>
        <b>{formatDate(eventInfo.timeText)}</b>
        <i>{eventInfo.event.title}</i>
      </GroupEvent>
    );
  } else {
    return (
      <>
        <b>{formatDate(eventInfo.timeText)}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }
};

export default Calendar;
